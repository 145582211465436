import React, { useState, useEffect } from "react";
import {
  Avatar,
  Grid,
  Button,
  Typography,
  Box,
  Stack,
  Divider,
  CircularProgress,
  Link,
  InputLabel,
  FormControl,
  TextField,
  Autocomplete,
} from "@mui/material";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import CONFIG from "../../config/config.env";
import Chip from "@mui/material/Chip";
import PhoneInput from "react-phone-number-input";
import { observer } from "mobx-react-lite";
import QuestionMarkIcon from "@mui/icons-material/QuestionMark";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import CustomTextField from "../Components/CustomTextField";
import {
  ParentAddDetails,
  ValidateAdmin,
  TextMarginAndCenter,
} from "./CustomStyledComponents";

import ApiRequestService from "../../services/api.service";
import { contentTypes } from "../../services/utils";
import { useRootStore } from "../../stores";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { errorToast, successToast } from "../../utils/toast";
import styled from "@emotion/styled";

const InputNumberWrapper = styled.div`
  .PhoneInputInput {
    border-bottom: 2.5px solid #1976d2;
    outline: none;
    background: transparent;
    color: black;
  }
`;
function AddUserDetails({ setIsAdmin, userInfo }) {
  const { authStore } = useRootStore();
  const ApiService = new ApiRequestService();

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("md"));
  const [mobileNumber, setMobileNumber] = useState("");
  const [data, setData] = React.useState(userInfo);
  const [updatedData, setUpdatedData] = React.useState();
  const [isDataLoading, setIsDataLoading] = React.useState(false);
  const [val, setVal] = useState([]);
  const [value, setValue] = useState([]);
  const [tagsList, setTagsList] = useState([
    "Startup",
    "Founder",
    "Developer",
    "Full Stack",
    "Influencer",
    "Content-Creator",
  ]);
  const [question, setQusetion] = useState([
    "Ask me anything about startup",
    "Think crypto",
    "Ping me!",
  ]);
  const removeItem = (index) => {
    setValue(value.filter((o, i) => index !== i));
  };
  const submit = async (event) => {
    setIsDataLoading(true);
    event.preventDefault();
    if (updatedData && updatedData.redirect_url !== "") {
      updatedData["isRedirected"] = "true";
    }
    console.log(data?.id);
    const header = { Authorization: data?.token };

    try {
      const response = await ApiService.putApi(
        `/users/${data?.id}/`,
        updatedData,
        {
          ...header,
          ...contentTypes.json,
        }
      );
      const { message, status } = response;

      if (status) {
        successToast("Profile details updated");
      } else {
        errorToast(message);
      }
    } catch (error) {
      const { message } = error;
      errorToast(message);
    }

    setIsDataLoading(false);
  };
  const handleChange = (event) => {
    const { value, name } = event.target;
    setData({ ...data, [name]: value });
    setUpdatedData({ ...updatedData, [name]: value });
  };

  useEffect(() => {
    let splitNumber = mobileNumber ? mobileNumber.split("+") : "";
    let numberWithCode = splitNumber.length ? splitNumber[1] : "";
    setData({
      ...data,
      [`whatsapp_url`]:
        numberWithCode && numberWithCode !== "91"
          ? `https://wa.me/${numberWithCode}`
          : "",
    });

    setUpdatedData({
      ...updatedData,
      [`whatsapp_url`]:
        numberWithCode && numberWithCode !== "91"
          ? `https://wa.me/${numberWithCode}`
          : "",
    });

    setUpdatedData({
      ...updatedData,
      profile_tags: value.join(),
      question_tags: val.join(),
    });
  }, [mobileNumber, value, val]);
  return (
    <ParentAddDetails>
      <ValidateAdmin>
        <TextMarginAndCenter variant="h6">Add User Details</TextMarginAndCenter>
        <TextMarginAndCenter variant="body1">
          Please enter following details for user
        </TextMarginAndCenter>
        <InputLabel
          shrink
          animated
          style={{ color: "#1976d2", textAlign: "center" }}
        >
          User profile link
        </InputLabel>
        <Typography
          variant="subtitle2"
          style={{ color: "#1976d2", textAlign: "center" }}
        >
          {`https://share.asambhav.org.in/at/${data?.username}`}
          <Link
            href={`https://share.asambhav.org.in/at/${data?.username}`}
            target="_blank"
          >
            <OpenInNewIcon title="open in new tab" />
          </Link>
        </Typography>

        <Grid item>
          <Box
            sx={{
              width: "100%",
              maxWidth: 1000,
              bgcolor: "#grey",
              borderRadius: "0.8%",
            }}
          >
            <Grid container rowSpacing={{ xs: 1 }} columnSpacing={{ xs: 1 }}>
              <Grid item container xs={12} sm={12} direction="column">
                <CustomTextField
                  label={
                    <div>
                      Email
                      <Tooltip
                        title="Enter User Email Here"
                        animate
                        shrink
                        placement="right-end"
                      >
                        <IconButton size="large">
                          <QuestionMarkIcon
                            style={{
                              fontSize: "15px",
                              color: "#1976d2",
                              marginBottom: "5px",
                            }}
                          />
                        </IconButton>
                      </Tooltip>
                    </div>
                  }
                  name="email"
                  type={"email"}
                  value={data?.email}
                  handleChange={handleChange}
                ></CustomTextField>

                <CustomTextField
                  label={
                    <div>
                      Username
                      <Tooltip
                        title="Enter User Username Here"
                        animate
                        shrink
                        placement="right-end"
                      >
                        <IconButton size="large">
                          <QuestionMarkIcon
                            style={{
                              fontSize: "15px",
                              color: "#1976d2",
                              marginBottom: "5px",
                            }}
                          />
                        </IconButton>
                      </Tooltip>
                    </div>
                  }
                  name="username"
                  type={"text"}
                  value={data?.username}
                  handleChange={handleChange}
                ></CustomTextField>
                <CustomTextField
                  label={
                    <div>
                      Display name
                      <Tooltip
                        title="Enter User Name Here"
                        animate
                        shrink
                        placement="right-end"
                      >
                        <IconButton size="large">
                          <QuestionMarkIcon
                            style={{
                              fontSize: "15px",
                              color: "#1976d2",
                              marginBottom: "5px",
                            }}
                          />
                        </IconButton>
                      </Tooltip>
                    </div>
                  }
                  name="display_name"
                  type={"text"}
                  value={data?.display_name}
                  handleChange={handleChange}
                ></CustomTextField>
                <CustomTextField
                  label={
                    <div>
                      Facebook Url
                      <Tooltip
                        title="Enter User Facebook Url Here"
                        animate
                        shrink
                        placement="right-end"
                      >
                        <IconButton size="large">
                          <QuestionMarkIcon
                            style={{
                              fontSize: "15px",
                              color: "#1976d2",
                              marginBottom: "5px",
                            }}
                          />
                        </IconButton>
                      </Tooltip>
                    </div>
                  }
                  name="facebook_url"
                  type={"text"}
                  value={data?.facebook_url}
                  handleChange={handleChange}
                ></CustomTextField>

                <CustomTextField
                  label={
                    <div>
                      Upi Id
                      <Tooltip
                        title="Enter User Upi Id Here"
                        animate
                        shrink
                        placement="right-end"
                      >
                        <IconButton size="large">
                          <QuestionMarkIcon
                            style={{
                              fontSize: "15px",
                              color: "#1976d2",
                              marginBottom: "5px",
                            }}
                          />
                        </IconButton>
                      </Tooltip>
                    </div>
                  }
                  name="upi_id"
                  type={"text"}
                  value={data?.upi_id}
                  handleChange={handleChange}
                ></CustomTextField>
                <CustomTextField
                  label={
                    <div>
                      LinkedIn Url
                      <Tooltip
                        title="Enter User LinkedIn Url Here"
                        animate
                        shrink
                        placement="right-end"
                      >
                        <IconButton size="large">
                          <QuestionMarkIcon
                            style={{
                              fontSize: "15px",
                              color: "#1976d2",
                              marginBottom: "5px",
                            }}
                          />
                        </IconButton>
                      </Tooltip>
                    </div>
                  }
                  name="linkedin_url"
                  type={"text"}
                  value={data?.linkedin_url}
                  handleChange={handleChange}
                ></CustomTextField>
                <CustomTextField
                  label={
                    <div>
                      Redirect Link
                      <Tooltip
                        title="Enter User Redirect Url Here"
                        animate
                        shrink
                        placement="right-end"
                      >
                        <IconButton size="large">
                          <QuestionMarkIcon
                            style={{
                              fontSize: "15px",
                              color: "#1976d2",
                              marginBottom: "5px",
                            }}
                          />
                        </IconButton>
                      </Tooltip>
                    </div>
                  }
                  name="redirect_url"
                  type={"text"}
                  value={data?.redirect_url}
                  handleChange={handleChange}
                ></CustomTextField>
                <div style={{ marginTop: "30px" }}>
                  <Autocomplete
                    value={value}
                    onChange={(event, newValue) => {
                      setValue(newValue);
                      console.log(value);
                    }}
                    multiple
                    id="tags-filled"
                    options={tagsList.map((tag) => tag)}
                    freeSolo
                    renderTags={(selected) => (
                      <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                        {selected.map((item, id) => (
                          <Chip
                            key={item}
                            label={item}
                            onDelete={() => removeItem(id)}
                          />
                        ))}
                      </Box>
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        focused
                        variant="standard"
                        label={
                          <div>
                            Profile Tags
                            <Tooltip
                              title="Click On Profile Tags or Enter another Tags"
                              animate
                              shrink
                              placement="right-end"
                            >
                              <IconButton size="large">
                                <QuestionMarkIcon
                                  style={{
                                    fontSize: "15px",
                                    color: "#1976d2",
                                    marginBottom: "5px",
                                  }}
                                />
                              </IconButton>
                            </Tooltip>
                          </div>
                        }
                        name="profile_tags"
                        type={"text"}
                        value={value}
                        placeholder="Search"
                      />
                    )}
                  />
                </div>

                <div style={{ marginTop: "20px" }}>
                  <Autocomplete
                    value={val}
                    onChange={(event, newVal) => {
                      setVal(newVal);
                      console.log(val);
                    }}
                    multiple
                    id="tags-filled"
                    options={question.map((tag) => tag)}
                    freeSolo
                    renderTags={(selected) => (
                      <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                        {selected.map((item) => (
                          <Chip key={item} label={item} />
                        ))}
                      </Box>
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        focused
                        variant="standard"
                        label={
                          <div>
                            Question Tags
                            <Tooltip
                              title="Click On Question Tags"
                              animate
                              shrink
                              placement="right-end"
                            >
                              <IconButton size="large">
                                <QuestionMarkIcon
                                  style={{
                                    fontSize: "15px",
                                    color: "#1976d2",
                                    marginBottom: "5px",
                                  }}
                                />
                              </IconButton>
                            </Tooltip>
                          </div>
                        }
                        name="question_tags"
                        type={"text"}
                        value={val}
                        placeholder="Search"
                      />
                    )}
                  />
                </div>
              </Grid>

              <Grid item container xs={12} sm={12} direction="column">
                {/* <CustomTextField label="Password" name="password" type={'password'} value={data?.password} handleChange={handleChange}></CustomTextField> */}
                <CustomTextField
                  label={
                    <div>
                      Youtube Link
                      <Tooltip
                        title="Enter User Youtube Link Here"
                        animate
                        shrink
                        placement="right-end"
                      >
                        <IconButton size="large">
                          <QuestionMarkIcon
                            style={{
                              fontSize: "15px",
                              color: "#1976d2",
                              marginBottom: "5px",
                            }}
                          />
                        </IconButton>
                      </Tooltip>
                    </div>
                  }
                  name="youtube_link"
                  type={"text"}
                  value={data?.youtube_link}
                  handleChange={handleChange}
                ></CustomTextField>

                {console.log(data)}
                {console.log(updatedData)}
                <CustomTextField
                  label={
                    <div>
                      Description
                      <Tooltip
                        title="Enter User Description Here"
                        animate
                        shrink
                        placement="right-end"
                      >
                        <IconButton size="large">
                          <QuestionMarkIcon
                            style={{
                              fontSize: "15px",
                              color: "#1976d2",
                              marginBottom: "5px",
                            }}
                          />
                        </IconButton>
                      </Tooltip>
                    </div>
                  }
                  name="description"
                  type={"text"}
                  value={data?.description}
                  handleChange={handleChange}
                ></CustomTextField>
                <CustomTextField
                  label={
                    <div>
                      Twitter Link
                      <Tooltip
                        title="Enter User Twitter Link Here"
                        animate
                        shrink
                        placement="right-end"
                      >
                        <IconButton size="large">
                          <QuestionMarkIcon
                            style={{
                              fontSize: "15px",
                              color: "#1976d2",
                              marginBottom: "5px",
                            }}
                          />
                        </IconButton>
                      </Tooltip>
                    </div>
                  }
                  name="twitter_url"
                  type={"text"}
                  value={data?.twitter_url}
                  handleChange={handleChange}
                ></CustomTextField>
                <CustomTextField
                  label={
                    <div>
                      Instagram Link
                      <Tooltip
                        title="Enter User Instagram Link Here"
                        animate
                        shrink
                        placement="right-end"
                      >
                        <IconButton size="large">
                          <QuestionMarkIcon
                            style={{
                              fontSize: "15px",
                              color: "#1976d2",
                              marginBottom: "5px",
                            }}
                          />
                        </IconButton>
                      </Tooltip>
                    </div>
                  }
                  name="instagram_url"
                  type={"text"}
                  value={data?.instagram_url}
                  handleChange={handleChange}
                ></CustomTextField>
                <CustomTextField
                  label={
                    <div>
                      Location
                      <Tooltip
                        title="Enter User Location Here"
                        animate
                        shrink
                        placement="right-end"
                      >
                        <IconButton size="large">
                          <QuestionMarkIcon
                            style={{
                              fontSize: "15px",
                              color: "#1976d2",
                              marginBottom: "5px",
                            }}
                          />
                        </IconButton>
                      </Tooltip>
                    </div>
                  }
                  name="location"
                  type={"text"}
                  value={data?.location}
                  handleChange={handleChange}
                ></CustomTextField>
                <CustomTextField
                  label={
                    <div>
                      Mailing List Label
                      <Tooltip
                        title="Enter User Mailing List Label Here"
                        animate
                        shrink
                        placement="right-end"
                      >
                        <IconButton>
                          <QuestionMarkIcon
                            style={{
                              fontSize: "15px",
                              color: "#1976d2",
                              marginBottom: "5px",
                            }}
                          />
                        </IconButton>
                      </Tooltip>
                    </div>
                  }
                  name="mailing_label"
                  type={"text"}
                  value={data?.mailing_label}
                  handleChange={handleChange}
                ></CustomTextField>

                <CustomTextField
                  label={
                    <div>
                      Mailng List Placeholder
                      <Tooltip
                        title="Enter User Mailing List Placeholder Here"
                        animate
                        shrink
                        placement="right-end"
                      >
                        <IconButton size="large">
                          <QuestionMarkIcon
                            style={{
                              fontSize: "15px",
                              color: "#1976d2",
                              marginBottom: "5px",
                            }}
                          />
                        </IconButton>
                      </Tooltip>
                    </div>
                  }
                  name="mailing_placeholder"
                  type={"text"}
                  value={data?.mailing_placeholder}
                  handleChange={handleChange}
                ></CustomTextField>

                <CustomTextField
                  label={
                    <div>
                      Mailng List Button
                      <Tooltip
                        title="Enter User Mailing List Button Here"
                        animate
                        shrink
                        placement="right-end"
                      >
                        <IconButton size="large">
                          <QuestionMarkIcon
                            style={{
                              fontSize: "15px",
                              color: "#1976d2",
                              marginBottom: "5px",
                            }}
                          />
                        </IconButton>
                      </Tooltip>
                    </div>
                  }
                  name="mailing_button"
                  type={"text"}
                  value={data?.mailing_button}
                  handleChange={handleChange}
                ></CustomTextField>

                <InputNumberWrapper>
                  <Grid
                    item
                    xs={12}
                    my={3}
                    sx={{ flexDirection: "column" }}
                    className="flex justify-center md:justify-start align-center  flex-col "
                  >
                    <InputLabel shrink animated style={{ color: "#1976d2" }}>
                      {
                        <div>
                          WhatsApp Number
                          <Tooltip
                            title="Enter User Whatsapp number Here"
                            animate
                            shrink
                            placement="right-end"
                          >
                            <IconButton size="large">
                              <QuestionMarkIcon
                                style={{
                                  fontSize: "15px",
                                  color: "#1976d2",
                                  marginBottom: "5px",
                                }}
                              />
                            </IconButton>
                          </Tooltip>
                        </div>
                      }
                    </InputLabel>
                    <PhoneInput
                      defaultCountry="IN"
                      value={mobileNumber}
                      onChange={setMobileNumber}
                    />
                  </Grid>
                </InputNumberWrapper>
              </Grid>
            </Grid>
          </Box>
        </Grid>

        <Button
          disabled={isDataLoading}
          variant="contained"
          onClick={submit}
          style={{ marginTop: "20px" }}
        >
          {isDataLoading ? <CircularProgress /> : "Submit"}
        </Button>
      </ValidateAdmin>
    </ParentAddDetails>
  );
}

export default observer(AddUserDetails);
