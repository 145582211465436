import React from "react";
import logo from "../images/asambhav-logo.png";

const ComingSoon = () => {
    return (
        <div className="h-screen w-screen bg-gradient-to-r from-rose-100 to-teal-100">
            <div className="flex flex-col gap-10 justify-center items-center h-full ">
                <img className="max-w-xs" src={logo} alt="" />
                <h2 className="text-center font-bold text-gray-800 text-3xl md:text-5xl uppercase mt-10 tracking-wider">
                    Coming Soon
                </h2>
            </div>
        </div>
    );
};

export default ComingSoon;
