import { Button } from "@mui/material";
import React, { useState } from "react";
import { useRootStore } from "../../stores";
import copy from "copy-to-clipboard";
import { successToast } from "../../utils/toast";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";

const GetCode = () => {
    const { authStore } = useRootStore();
    const [url, setUrl] = useState()

    const handleClick = async () => {
        await authStore.onGenerateCode()
        const code = await JSON.parse(localStorage.getItem("user_code"))
        const link =
            window.location.hostname === "localhost"
                ? `http://${window.location.hostname}:3000/at/${code}`
                : `https://${window.location.hostname}/at/${code}`;
        setUrl(link)
        copy(url);
        successToast(`Code Generated and Copied to Clipboard`);
    }

    const copyUrl = () =>{
        copy(url);
        successToast(` Copied to Clipboard`);
    }

    return (
        <div className="h-screen w-screen bg-green-800">
            <div className="flex flex-col gap-4 justify-center items-center h-full ">
                <Button
                    variant="contained"
                    size="large"
                    onClick={handleClick}
                >
                    Click to get URL with unique code
                </Button>
                {url && <Button
                    variant="contained"
                    onClick={copyUrl}
                    sx={[
                        {
                            color: "white",
                            textTransform: "lowercase",
                            textDecoration: "underline",
                            bgcolor: "rgba(0, 0, 0, 0.3)"

                        },
                        {
                            "&:hover": {
                                color: "white",
                                textTransform: "lowercase",
                                textDecoration: "underline",
                            bgcolor: "rgba(0, 0, 0, 0.2)"

                            },
                        },
                    ]}
                    endIcon={<ContentCopyIcon />}
                >{url}</Button>}
            </div>
        </div>
    );
};

export default GetCode;
