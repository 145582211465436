import React from "react";
import { Link } from "react-router-dom";
import CONFIG from "../../config/config.env";

const LoginHeader = () => {
    return (
        <div className="container mx-auto p-3">
            <Link className="font-bold i text-xl md:text-2xl" to="/">
            {CONFIG.APP_NAME}
            </Link>
        </div>
    );
};

export default LoginHeader;
