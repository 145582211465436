import React from "react";
import { Grid, Button, Divider, InputLabel } from "@mui/material";
import LoginHeader from "../Login/LoginHeader";
import { useState, useEffect } from "react";
import { useNavigate, useParams, Link } from "react-router-dom";
import CustomTextField from "../Components/CustomTextField";
import { useRootStore } from "../../stores";
import styled from "@emotion/styled";
import PhoneInput from "react-phone-number-input";
import { createEvent } from "../../services/Events"
import { successToast, errorToast } from "../../utils/toast";

const InputFieldWrapper = styled.div``;
const InputNumberWrapper = styled.div`
  .PhoneInputInput {
    border-bottom: 2.5px solid #1976d2;
    outline: none;
    background: transparent;
    color: black;
  }
`;

const eventUserTrue = (isEventUserTrue) => {
  return !!isEventUserTrue;
};

const EventRegisteration = (props) => {
  const { user_code } = useParams();
  const { authStore } = useRootStore();
  const [data, setData] = useState();
  const navigate = useNavigate();

  useEffect(() => {
    const isLoggedIn = authStore.isUserLoggedIn();
    if (isLoggedIn) {
      authStore.reset();
    }
  }, [authStore.state]);

  const handleChange = (event) => {
    let { value, name } = event.target;
    setData({ ...data, [name]: value });

  };

  const submit = async (event) => {
    event.preventDefault();
    let res = await createEvent(data)
    if(res.status){
      successToast("Event created")
      console.log(data)
    }
    else{
      errorToast(res.message)
    }
  };

  return (
    <div style={{ padding: 30 }}>
      <div>
        <LoginHeader></LoginHeader>

        <Divider sx={{ background: "white" }} />
        <div className="p-3">
          <h2 className="text-xl md:text-3xl font-bold text-center mt-5">
            Register your Community / Event
          </h2>
          <div className="mt-10"></div>
          <Grid
            container
            direction={"column"}
            justifyContent={"center"}
            alignItems={"center"}
          >
            <div className="my-3 flex justify-center align-center">
              <CustomTextField
                label="Community / Event Name"
                name="community_name"
                type="text"
                value={data?.community_name}
                handleChange={handleChange}
              ></CustomTextField>
            </div>
            <div className="my-3 flex justify-center align-center">
              <CustomTextField
                label="Organizer Name"
                name="organizer_name"
                type="text"
                value={data?.organizer_name}
                handleChange={handleChange}
              ></CustomTextField>
            </div>
            <div className="my-3 flex justify-center align-center">
              <CustomTextField
                label="Organizer Email"
                name="organizer_email"
                type="email"
                value={data?.organizer_email}
                handleChange={handleChange}
              ></CustomTextField>
            </div>

            <div className="my-3 flex justify-center align-center">
              <CustomTextField
                label="Community / Event Email"
                name="community_email"
                type="email"
                value={data?.community_email}
                handleChange={handleChange}
              ></CustomTextField>
            </div>
            <div className="my-3 flex justify-center align-center">
              <InputNumberWrapper>
                <Grid
                  item
                  xs={12}
                  mt={3}
                  sx={{ flexDirection: "column" }}
                  className="flex justify-center md:justify-start align-center  flex-col "
                >
                  <InputLabel shrink style={{ color: "#1976d2" }}>
                    {<div>Contact Number 1</div>}
                  </InputLabel>
                  <PhoneInput
                    label="Contact Number"
                    name="contact_number1"
                    value={data?.contact_number1}
                    onChange={(e)=>setData({ ...data, ["contact_number1"]: e })}
                    style={{ marginLeft: "10px" }}
                    defaultCountry="IN"
                  />
                </Grid>
              </InputNumberWrapper>
            </div>
            <div className="my-3 flex justify-center align-center">
              <InputNumberWrapper>
                <Grid
                  item
                  xs={12}
                  mt={3}
                  sx={{ flexDirection: "column" }}
                  className="flex justify-center md:justify-start align-center  flex-col "
                >
                  <InputLabel shrink style={{ color: "#1976d2" }}>
                    {<div>Contact Number 2</div>}
                  </InputLabel>
                  <PhoneInput
                    label="Contact Number"
                    name="contact_number2"
                    value={data?.contact_number2}
                    onChange={(e)=>setData({ ...data, ["contact_number2"]: e })}
                    style={{ marginLeft: "10px" }}
                    defaultCountry="IN"
                  />
                </Grid>
              </InputNumberWrapper>
            </div>
            <div className="my-3 flex justify-center align-center">
              <InputFieldWrapper>
                <Grid
                  item
                  xs={12}
                  mt={3}
                  sx={{ flexDirection: "column" }}
                  className="flex justify-center md:justify-start align-center  flex-col "
                >
                  <InputLabel
                    shrink
                    animated="true"
                    style={{ color: "#1976d2" }}
                  >{`Event Start Date & Time`}</InputLabel>
                  <input
                    label="Event Start Date & Time"
                    name="valid_from"
                    value={data?.valid_from}
                    onChange={handleChange}
                    type="datetime-local"
                    style={{
                      width: "105%",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  />
                </Grid>
              </InputFieldWrapper>
            </div>
            <div className="my-3 flex justify-center align-center">
              <InputFieldWrapper>
                <Grid
                  item
                  xs={12}
                  mt={3}
                  sx={{ flexDirection: "column" }}
                  className="flex justify-center md:justify-start align-center  flex-col "
                >
                  <InputLabel
                    shrink
                    animated="true"
                    style={{ color: "#1976d2" }}
                  >{`Event End Date & Time`}</InputLabel>
                  <input
                    label="Event End Date & Time"
                    name="valid_till"
                    value={data?.valid_till}
                    onChange={handleChange}
                    type="datetime-local"
                    style={{
                      width: "105%",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  />
                </Grid>
              </InputFieldWrapper>
            </div>
            <div className="my-7 flex justify-center align-center">
              <Button variant="contained" onClick={submit} size="large">
                Register
              </Button>
            </div>
          </Grid>
        </div>
      </div>
    </div>
  );
};

export default EventRegisteration;
