import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { useRootStore } from "../../stores";
import { Stack } from "@mui/material";
import CONFIG from "../../config/config.env";

const ProfileHeader = () => {
  const { authStore } = useRootStore();
  const navigate = useNavigate();

  return (
    <Stack
      p={3}
      justifyContent="space-between"
      alignItems="center"
      flexDirection="row"
    >
      <Link className="font-bold i text-xl md:text-2xl" to="/">
      {CONFIG.APP_NAME}
      </Link>
      <div
        className="btn btn-warning mr-1 btn-sm md:btn-md  md:mr-3 md:ml-auto"
        onClick={() => {
          authStore.reset();
          navigate("/", { replace: true });
        }}
      >
        Log Out
      </div>
    </Stack>
  );
};

export default ProfileHeader;
