import React, { useMemo } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useRootStore } from "../../stores";
import CONFIG from "../../config/config.env";

const HomeHeader = () => {
  const { authStore } = useRootStore();
  const navigate = useNavigate();
  const isLoggedIn = useMemo(
    () => authStore.isUserLoggedIn(),
    [authStore.state]
  );

  const navItems = (
    <>
      <li>
        <Link className="font-bold text-lg" to="/">
          Home
        </Link>
      </li>
      <li>
        <Link className="font-bold text-lg" to="/dashboard">
          Dashboard
        </Link>
      </li>
    </>
  );
  return (
    <div className="navbar rounded-lg absolute top-10 mb-10  bg-slate-100">
      <div className="navbar-start">
        <div className="dropdown">
          <label tabIndex="0" className="btn btn-ghost lg:hidden">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-5 w-5"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M4 6h16M4 12h8m-8 6h16"
              />
            </svg>
          </label>
          <ul
            tabIndex="0"
            className="menu menu-compact dropdown-content mt-3 p-2 shadow bg-blue-600 text-white rounded-box w-52"
          >
            <li>
              <Link className="font-bold text-lg" to="/">
                Home
              </Link>
            </li>
            <li tabIndex="0">
              <Link to="/dashboard" className="font-bold text-lg">
                Dashboard
                <svg
                  className="fill-current"
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                >
                  <path d="M8.59,16.58L13.17,12L8.59,7.41L10,6L16,12L10,18L8.59,16.58Z" />
                </svg>
              </Link>
              {/* <ul className="p-2 ml-3 bg-blue-600 text-white">
                                <li>
                                    <Link
                                        className="font-bold text-lg"
                                        to="/dashboard/overview"
                                    >
                                        Overview
                                    </Link>
                                </li>
                                <li>
                                    <Link
                                        className="font-bold text-lg"
                                        to="/dashboard/orders"
                                    >
                                        Orders
                                    </Link>
                                </li>
                            </ul> */}
            </li>
            <li>
              <Link className="font-bold text-lg" to="/">
                About
              </Link>
            </li>
            {!isLoggedIn && (
              <>
                <li>
                  <Link className="font-bold text-lg" to="/login">
                    Login
                  </Link>
                </li>
                <li>
                  <Link className="font-bold text-lg" to="/register">
                    Register
                  </Link>
                </li>
              </>
            )}
          </ul>
        </div>
        <Link to="/" className="btn btn-ghost normal-case i text-xl md:text-2xl">
          {CONFIG.APP_NAME}
        </Link>
      </div>
      <div className="navbar-center hidden lg:flex">
        <ul className="menu menu-horizontal p-0">{navItems}</ul>
      </div>
      <div className="navbar-end">
        {!isLoggedIn ? (
          <div className="lg:visible invisible">
            <Link to="/login" className="btn btn-xs md:btn-md btn-success mr-3">
              Log in
            </Link>
            <Link to="/register" className="btn btn-xs md:btn-md btn-warning">
              Register
            </Link>
          </div>
        ) : (
          <div
            className="btn btn-warning mr-3 btn-sm md:btn-md"
            onClick={() => {
              authStore.reset();
              navigate("/", { replace: true });
            }}
          >
            Log Out
          </div>
        )}
      </div>
    </div>
  );
};

export default HomeHeader;
