import React from "react";
import styled from "@emotion/styled";

const Container = styled.div`
  width: 80%;
  margin: auto;
  margin-top: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  @media (max-width: 768px) {
    width: 100%;
  }
`;
const Border = styled.div`
  border-bottom: 2px solid lightgrey;
  width: 100%;
`;

const Content = styled.span`
  padding: 5px 2px;
  font-weight: 500;
  font-size: 22;
  color: lightgrey;
`;

const DividerWithText = ({ children }) => {
  return (
    <Container>
      <Border />
      <Content>{children}</Content>
      <Border />
    </Container>
  );
};
export default DividerWithText;
