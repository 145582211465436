import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// toast.configure();

export const successToast = (message, onClose = () => {}) => {
  if (!message) return;
  toast.success(message, {
    onClose
  });
};

export const errorToast = (message, onClose = () => {}) => {
  if (!message) return;
  toast.error(message, {
    onClose
  });
};

export const infoToast = (message, onClose = () => {}) => {
  if (!message) return;
  toast.info(message, {
    onClose
  });
  console.log(!message);
};

export const updateMetadata = (pageName) => {
  document.title = `nikhil-fin | ${pageName}`;
};
