// import dotenv from 'dotenv';

// dotenv.config();

let CONFIG = {};

switch (process.env.REACT_APP_NODE_ENV) {
  case "production":
    CONFIG = {
      PUBLIC_URL: process.env.REACT_APP_PUBLIC_URL,
      APP_NAME: process.env.REACT_APP_NAME,
      SITE_KEY: process.env.REACT_APP_GOOGLE_SITE_KEY,
      LINKEDIN_SCOPE: process.env.REACT_APP_LINKEDIN_SCOPE,
      LINKEDIN_REDIRECT: process.env.REACT_APP_LINKEDIN_REDIRECT,
      LINKEDIN_CLIENT_ID: process.env.REACT_APP_LINKEDIN_CLIENT_ID,
      LINKEDIN_REDIRECT_CONNECT:
        process.env.REACT_APP_LINKEDIN_REDIRECT_CONNECT,
      LINKEDIN_CLIENT_SECRET: process.env.REACT_APP_LINKEDIN_CLIENT_SECRET,
      GOOGLE_REDIRECT: process.env.REACT_APP_GOOGLE_REDIRECT,
      GOOGLE_CLIENT_ID: process.env.REACT_APP_GOOGLE_CLIENT_ID,
      GOOGLE_CLIENT_SECRET: process.env.REACT_APP_GOOGLE_CLIENT_SECRET,
      GOOGLE_SCOPE: process.env.REACT_APP_GOOGLE_SCOPE,
    };
    // google config
    break;
  case "development":
    CONFIG = {
      PUBLIC_URL: process.env.REACT_APP_PUBLIC_URL,
      APP_NAME: process.env.REACT_APP_NAME,
      SITE_KEY: process.env.REACT_APP_GOOGLE_SITE_KEY,
      LINKEDIN_SCOPE: process.env.REACT_APP_LINKEDIN_SCOPE,
      LINKEDIN_REDIRECT: process.env.REACT_APP_LINKEDIN_REDIRECT,
      LINKEDIN_CLIENT_ID: process.env.REACT_APP_LINKEDIN_CLIENT_ID,
      LINKEDIN_REDIRECT_CONNECT:
        process.env.REACT_APP_LINKEDIN_REDIRECT_CONNECT,
      LINKEDIN_CLIENT_SECRET: process.env.REACT_APP_LINKEDIN_CLIENT_SECRET,
      GOOGLE_REDIRECT: process.env.REACT_APP_GOOGLE_REDIRECT,
      GOOGLE_SCOPE: process.env.REACT_APP_GOOGLE_SCOPE,
      GOOGLE_CLIENT_ID: process.env.REACT_APP_GOOGLE_CLIENT_ID,
      GOOGLE_CLIENT_SECRET: process.env.REACT_APP_GOOGLE_CLIENT_SECRET,
    };
    break;
  case "test":
  default:
    CONFIG = {
      PUBLIC_URL: process.env.REACT_APP_PUBLIC_URL,
      APP_NAME: process.env.REACT_APP_NAME,
      SITE_KEY: process.env.REACT_APP_GOOGLE_SITE_KEY,
      LINKEDIN_STATE: process.env.REACT_APP_LINKEDIN_STATE,
      LINKEDIN_SCOPE: process.env.REACT_APP_LINKEDIN_SCOPE,
      LINKEDIN_REDIRECT: process.env.REACT_APP_LINKEDIN_REDIRECT,
      LINKEDIN_CLIENT_ID: process.env.REACT_APP_LINKEDIN_CLIENT_ID,
      LINKEDIN_REDIRECT_CONNECT:
        process.env.REACT_APP_LINKEDIN_REDIRECT_CONNECT,
      LINKEDIN_CLIENT_SECRET: process.env.REACT_APP_LINKEDIN_CLIENT_SECRET,
      GOOGLE_REDIRECT: process.env.REACT_APP_GOOGLE_REDIRECT,
      GOOGLE_SCOPE: process.env.REACT_APP_GOOGLE_SCOPE,
      GOOGLE_CLIENT_ID: process.env.REACT_APP_GOOGLE_CLIENT_ID,
      GOOGLE_CLIENT_SECRET: process.env.REACT_APP_GOOGLE_CLIENT_SECRET,
    };
    break;
}

export default CONFIG;
