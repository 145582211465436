import React from "react";
import ApiCalendar from "react-calendar-with-meet";
import {
  Button,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
  InputLabel,
  CircularProgress,
} from "@mui/material";
import styled from "@emotion/styled";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CustomTextField from "./CustomTextField";

import DividerWithText from "./DividerWithText";
import { errorToast, successToast } from "../../utils/toast";
const config = {
  clientId: process.env.REACT_APP_GOOGLE_CLIENT_ID,
  apiKey: process.env.REACT_APP_GOOGLE_API_KEY,
  scope: "https://www.googleapis.com/auth/calendar",
  discoveryDocs: [
    "https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest",
  ],
};
const apiCalendar = new ApiCalendar(config);
function makeid() {
  var text = "";
  var possible = "0123456789abcdefghijklmnopqrstuv";

  for (var i = 0; i < 5; i++)
    text += possible.charAt(Math.floor(Math.random() * possible.length));

  return text;
}
const FieldWrapper = styled.div`
  padding: 10px 0;
  text-align: left;
`;
const InputFieldWrapper = styled.div`
  padding-top: 20px;
  text-align: left;
`;
export default function GoogleCalendar(props) {
  const [showForm, setShowForm] = React.useState(false);
  const [isEventSubmitting, setEventSubmitting] = React.useState(false);
  const [accordianOpen, setAccordianOpen] = React.useState(false);
  const [title, setTitle] = React.useState("");
  const [description, setDescription] = React.useState("");
  const [startEvent, setStartEvent] = React.useState("");
  const [endEvent, setEndEvent] = React.useState("");
  const [showSignInButton, setShowSigninButton] = React.useState(false);

  React.useEffect(() => {
    if (accordianOpen) {
      apiCalendar.handleAuthClick();
    }
  }, [accordianOpen]);
  function getEvent(startEventTime, EndEventTime, title, description) {
    let startDate = new Date(startEventTime);
    let endDate = new Date(EndEventTime);
    const result = {
      summary: title,
      time: 60,
      id: makeid() + "1",
      description: description,
      calendarId: "primary",
      start: {
        dateTime: startDate.toISOString(),
        timeZone: "Asia/Kolkata",
      },
      end: {
        dateTime: endDate.toISOString(),
        timeZone: "Asia/Kolkata",
      },
      attendees: [{ email: props.email }],
      colorId: 1,
      conferenceDataVersion: 1,
      conferenceData: {
        createRequest: {
          requestId: makeid(),
          conferenceSolutionKey: {
            type: "hangoutsMeet",
          },
          status: {
            statusCode: "success",
          },
        },
      },
    };
    return result;
  }

  const handleChangeTitle = (event) => {
    const { value } = event.target;
    setTitle(value);
  };

  const handleChangeDescription = (event) => {
    const { value } = event.target;
    setDescription(value);
  };

  function createEvent(
    startEventTime,
    EndEventTime,
    title,
    description,
    isInstant
  ) {
    function SendEvent() {
      setEventSubmitting(true);

      let dataObj = getEvent(startEventTime, EndEventTime, title, description);
      console.log(dataObj);
      try {
        if (apiCalendar.sign){
          apiCalendar
            .createEvent(dataObj, "primary", "all")
            .then((result) => {
              successToast("Event created successfully");
              console.log(result);

              setEventSubmitting(false);
            })
            .catch((error) => {
              setEventSubmitting(false);
              if (error?.result?.error?.message) {
                errorToast(error?.result?.error?.message);
              } else {
                setShowSigninButton(true);
                errorToast("Please sign in to create an event");
              }
            });

          }else{
            
            setEventSubmitting(false);
            errorToast("Please sign in to create an event");
          }
      } catch (error) {
        setEventSubmitting(false);
        if (error?.result?.error?.message) {
          errorToast(error?.result?.error?.message);
        } else {
          setShowSigninButton(true);
          errorToast("Please sign in to create an event");
        }
      }
    }

    if (isInstant) {
      SendEvent();
    } else {
      let isFormValid = checkDataValidation();

      if (isFormValid) {
        SendEvent();
      }
    }
  }

  function checkDataValidation() {
    if (!title) {
      errorToast("Please enter title");
      return false;
    } else if (!description) {
      errorToast("Please enter description");
      return false;
    } else if (!startEvent) {
      errorToast("Please select start event date and time");
      return false;
    } else if (!endEvent) {
      errorToast("Please select end event date and time");
      return false;
    } else {
      return true;
    }
  }
  function setTitleAndDescriptionForInstantEvents(timestamp) {
    let mobileData = props.mob ? props.mob.split("/") : "";
    let mobileNumber = mobileData
      ? `+${mobileData[mobileData.length - 1]}`
      : "";

    let obj = {};
    obj.title = `ConnektWithMe ${apiCalendar.userEmailData} <> ${
      props.name ? props.name : props.email
    }`;
    obj.description = `follow up call after meeting on, ${timestamp}${
      mobileNumber ? ", mobile number: " + mobileNumber : ""
    }
    `;

    return obj;
  }
  function getNextDayOfTheWeek(
    dayName,
    excludeToday = true,
    refDate = new Date(),
    hours,
    minutes
  ) {
    const dayOfWeek = ["sun", "mon", "tue", "wed", "thu", "fri", "sat"].indexOf(
      dayName.slice(0, 3).toLowerCase()
    );
    if (dayOfWeek < 0) return;
    refDate.setHours(hours, minutes, 0);
    refDate.setDate(
      refDate.getDate() +
        +!!excludeToday +
        ((dayOfWeek + 7 - refDate.getDay() - +!!excludeToday) % 7)
    );
    return refDate;
  }
  return (
    <>
      {!showForm && (
        <Button
          disabled={!props.isUserSigned}
          variant="contained"
          onClick={() => {
            setShowForm(true);
          }}
        >
          create a custom event for me
        </Button>
      )}

      {showForm && (
        <>
          <InputLabel
            animated="true"
            style={{ color: "#1976d2", textAlign: "center" }}
          >{`Create a custom event`}</InputLabel>
          <FieldWrapper style={{ paddingTop: "0px !important" }}>
            <CustomTextField
              label={"Title"}
              name="title"
              type={"text"}
              placeholder={"Enter Title For The Event"}
              value={title}
              handleChange={handleChangeTitle}
            ></CustomTextField>
          </FieldWrapper>
          <FieldWrapper>
            <CustomTextField
              label={"Description"}
              name="description"
              type={"text"}
              placeholder={"Enter Description For The Event"}
              value={description}
              handleChange={handleChangeDescription}
            ></CustomTextField>
          </FieldWrapper>
          <InputFieldWrapper>
            <InputLabel
              shrink
              animated="true"
              style={{ color: "#1976d2" }}
            >{`Start event time`}</InputLabel>
            <input
              type="datetime-local"
              value={startEvent}
              onChange={(e) => {
                setStartEvent(e.target.value);
              }}
            />
          </InputFieldWrapper>
          <InputFieldWrapper>
            <InputLabel
              shrink
              animated="true"
              style={{ color: "#1976d2" }}
            >{`End event time`}</InputLabel>
            <input
              type="datetime-local"
              value={endEvent}
              onChange={(e) => {
                setEndEvent(e.target.value);
              }}
            />
          </InputFieldWrapper>
          <InputFieldWrapper>
            <div style={{ textAlign: "center" }}>
              {isEventSubmitting ? (
                <CircularProgress />
              ) : (
                <Button
                  variant="contained"
                  onClick={() => {
                    createEvent(
                      startEvent,
                      endEvent,
                      title,
                      description,
                      false
                    );
                  }}
                >
                  Create Event
                </Button>
              )}
            </div>
          </InputFieldWrapper>
        </>
      )}
    </>
  );
}
