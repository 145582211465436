import React from "react";
import styled from "@emotion/styled";
import CloseIcon from "@mui/icons-material/Close";
import {
  Card,
  Typography,
  Divider,
  Button,
  ListItem,
  InputLabel,
  MenuItem,
  FormControl,
  Select,
} from "@mui/material";
import { useRootStore } from "../../stores";
import { errorToast } from "../../utils/toast";

const MainModalBox = styled.div`
  position: absolute;
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.7);
  overflow: hidden;
  z-index: 100;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const CardTitleBox = styled.div`
  padding: 15px 10px;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const AddTimeForm = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 50px;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const AddPadding = styled.div`
  padding: 20px;
  width: calc(100% - 40%);
  margin: auto;
  @media (max-width: 768px) {
    width: calc(100% - 10%);
  }
`;
const CenterBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;
const FormCard = styled(Card)`
  width: calc(100% - 40%);
  margin: auto;

  @media (max-width: 768px) {
    width: calc(100% - 10%);
  }
`;


export default function ModalFooter({setFooterModal,content,privacyActive}) {
    function CardHeader() {
        return (
          <CardTitleBox>
            <Typography variant="h6">{privacyActive?"Privacy notice":"Terms of use"}</Typography>
            <CloseIcon
              onClick={() => {
                setFooterModal(false);
              }}
              style={{ color: "black", fontSize: "30px", cursor: "pointer" }}
            />
          </CardTitleBox>
        );
      }
  return (
    <MainModalBox>
      <FormCard variant="outlined">
        <CardHeader />
        <Divider />
{content}
      </FormCard>
    </MainModalBox>
  )
}
