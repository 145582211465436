import React from "react";
import { queryToObject } from "../services/helperLinkedin";
import { Oauth } from "../services/oauthLinkedin";
import { CircularProgress } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useRootStore } from "../stores";

function LinkedinAuth() {
  const { authStore } = useRootStore();
  const navigate = useNavigate();
  var hit = true;
  React.useEffect(() => {
    const linkedinAuth = queryToObject(window.location.search);

    if (linkedinAuth.hasOwnProperty("code")) {
      if (hit) {
        console.log("present");
        callAuth(linkedinAuth.code);
        hit = false;
      }
    }
  }, []);

  async function callAuth(code){
    let email = await Oauth(code)
    await authStore.onSignUpLinkedin({email:email, username:email});
    navigate("/dashboard", { replace: true });
  }

  return (
    <div className="my-7 flex justify-center align-center">
      <CircularProgress />
    </div>
  );
}

export default LinkedinAuth;
