import React from 'react';
import AuthStore from './AuthStore';

const RootStoreContext = React.createContext(null);

export const RootStoreProvider = ({ children }) => {
  const authStore = new AuthStore();
  return <RootStoreContext.Provider value={{ authStore }}>{children}</RootStoreContext.Provider>;
};

export const useRootStore = () => React.useContext(RootStoreContext);

// export default RootStore
RootStoreProvider.propTypes = {
  children: React.Component
};
