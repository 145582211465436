import styled from "@emotion/styled";
import { Card, TextField, Typography } from "@mui/material";

export const ParentBox = styled.div`
  background-color: #ffdee9;
  background-image: linear-gradient(0deg, #ffdee9 0%, #b5fffc 100%);
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;
export const ValidateAdmin = styled(Card)`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 20px;
  box-sizing: border-box;
`;

export const TextMarginAndCenter = styled(Typography)`
  text-align: center;
  margin-bottom: 20px;
`;

export const InputField = styled(TextField)`
  margin-bottom: 20px;
`;

export const ParentAddDetails = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 30px 0;
  overflow-y: auto;
  @media (max-width: 768px) {
    padding: 20px;
  }
`;
