import React, {useState} from "react";
import { Grid, TextField, IconButton, InputAdornment } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { set } from "react-hook-form";
import {VisibilityOff, Visibility} from "@mui/icons-material"

const CustomPasswordField = (props) => {
    const { name, value, label, handleChange } = props;
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down("md"));
    let styleObj = { width: "150%", height: 10, minWidth:"230px" };
    const [showPassword, setShowPassword]= useState();

    const handleClickShowPassword = () => {
        setShowPassword(!showPassword)
    }

    return (
        <div>
            <Grid
                item
                xs={12}
                my={3}
                className="flex justify-center md:justify-start align-center"
            >
                <TextField
                    focused
                    name={name}
                    value={value}
                    onChange={(e) => handleChange(e)}
                    label={label}
                    type={showPassword ? 'text' : 'password'}
                    variant="standard"
                    sx={matches ? styleObj : { ...styleObj, maxWIdth: 300 }}
                    InputProps={{
                        endAdornment: (
                          <InputAdornment position="end" sx={{position:"absolute",right:"2px"}}>
                            <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                        >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                          </InputAdornment>
                        )
                      }}
                >
                </TextField>
                
                
            </Grid>
        </div>
    );
};

export default CustomPasswordField;
