import {
  Grid,
  Button,
  Typography,
  Box,
  Divider,
  CircularProgress,
  InputLabel,
  TextField,
  Autocomplete,
} from "@mui/material";
import "react-phone-number-input/style.css";
import styled from "@emotion/styled";
import { useRootStore } from "../../stores";
import ProfileHeader from "./ProfileHeader";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import React, { useState, useEffect } from "react";
import AvatarUpload from "../Components/AvatarUpload";
import CustomTextField from "../Components/CustomTextField";
import CONFIG from "../../config/config.env";
import Chip from "@mui/material/Chip";
import PhoneInput from "react-phone-number-input";
import { observer } from "mobx-react-lite";
import QuestionMarkIcon from "@mui/icons-material/QuestionMark";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import QrCodeIcon from "@mui/icons-material/QrCode";
import QRCode from "qrcode";
import { useNavigate, Link } from "react-router-dom";

const InputNumberWrapper = styled.div`
  .PhoneInputInput {
    border-bottom: 2.5px solid #1976d2;
    outline: none;
    background: transparent;
    color: black;
  }
`;

const UpdateProfile = ({ isModalOpen, setIsModalOpen }) => {
  const [mobileNumber, setMobileNumber] = useState("");
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("md"));
  const { authStore } = useRootStore();
  const [data, setData] = useState();
  const [updatedData, setUpdatedData] = useState();
  const [value, setValue] = useState([]);
  const [tagsList, setTagsList] = useState([
    "Startup",
    "Founder",
    "Developer",
    "Full Stack",
    "Influencer",
    "Content-Creator",
  ]);
  const navigate = useNavigate();

  const removeItem = (index) => {
    setValue(value.filter((o, i) => index !== i));
  };
  const [val, setVal] = useState([]);
  const [question, setQusetion] = useState([
    "Ask me anything about startup",
    "Think crypto",
    "Ping me!",
  ]);

  const link =
    window.location.hostname === "localhost"
      ? `http://${window.location.hostname}:3000/at/${data?.username}`
      : `https://${window.location.hostname}/at/${data?.username}`;
  const handleChange = (event) => {
    const { value, name } = event.target;
    setData({ ...data, [name]: value });
    setUpdatedData({ ...updatedData, [name]: value });
  };
  useEffect(() => {
    let splitNumber = mobileNumber ? mobileNumber.split("+") : "";
    let numberWithCode = splitNumber.length ? splitNumber[1] : "";
    setData({
      ...data,
      [`whatsapp_url`]:
        numberWithCode && numberWithCode !== "91"
          ? `https://wa.me/${numberWithCode}`
          : "",
    });

    setUpdatedData({
      ...updatedData,
      [`whatsapp_url`]:
        numberWithCode && numberWithCode !== "91"
          ? `https://wa.me/${numberWithCode}`
          : "",
    });
  }, [mobileNumber]);

  useEffect(() => {
    setUpdatedData({
      ...updatedData,
      profile_tags: value.join(),
      question_tags: val.join(),
    });
  }, [value, val]);

  let boxStyles = {
    width: "100%",
    bgcolor: "#555555",
    borderRadius: "5%",
  };
  const submit = async (event) => {
    event.preventDefault();
    if (updatedData && updatedData.redirect_url !== "") {
      updatedData["isRedirected"] = "true";
    }
    await authStore.onPutUser(updatedData, data?.id);
  };

  const fetchUsers = async () => {
    await authStore.onGetUser();
    const user = JSON.parse(localStorage.getItem("userData"));
    setData(user);
    if (user.hasOwnProperty("whatsapp_url")) {
      let splitData = user.whatsapp_url.split("/");
      let mobileNumber = splitData[splitData.length - 1];
      let number = mobileNumber ? "+" + mobileNumber : "";
      setMobileNumber(number);
    }
    if (user.hasOwnProperty("profile_tags")) {
      let profileTags = user?.profile_tags?.split(",");
      setValue(profileTags ? profileTags : []);
    }
    if (user.hasOwnProperty("question_tags")) {
      let questionTags = user?.question_tags?.split(",");
      setVal(questionTags ? questionTags : []);
    }
  };

  useEffect(() => {
    fetchUsers();
  }, []);

  const [qrCode, setQRCode] = useState("");

  const getQRCode = () => {
    const QRData = `https://${window.location.hostname}/${data?.user_code}`;

    QRCode.toDataURL(
      QRData,
      {
        width: 200,
        margin: 2,
        color: {
          // dark: '#335383FF',
          // light: '#EEEEEEFF'
        },
      },
      (err, url) => {
        if (err) return console.error(err);
        setQRCode(url);
      }
    );
  };

  console.log("userdata", data);

  return (
    <div style={{ padding: 30 ,background:"white"}}>
      <div>
        <ProfileHeader></ProfileHeader>
        <div className="p-3">
          <h2 className="text-xl md:text-3xl font-bold text-center mt-5">
            Profile Settings
          </h2>
        </div>
      </div>

      <Grid
        container
        mt={4}
        rowSpacing={1}
        columnSpacing={{ xs: 1, sm: 2, md: 3 }}
        justifyContent="center"
        sx={{ width: "100%" }}
      >
        <Grid item md={4} justifyContent="center" alignItems="center" xs={12}>
          <Box sx={matches ? boxStyles : { ...boxStyles, maxWIdth: 360 }}>
            <Box sx={{ my: 1, mx: 2 }}>
              <Grid
                container
                alignItems="center"
                justifyContent="center"
                py={2}
              >
                <Grid item xs="auto">
                  <AvatarUpload />
                </Grid>
              </Grid>
              <Grid
                container
                alignItems="center"
                justifyContent="center"
                py={2}
              >
                <Grid item xs="auto">
                  <Button
                    variant="contained"
                    onClick={() => {
                      setIsModalOpen(true);
                    }}
                  >
                    Add Meeting time
                  </Button>
                </Grid>
              </Grid>
            </Box>
            <Divider variant="middle" />
            <Box sx={{ my: 1, mx: 2, py: 2, pb: 5 }}>
              <Grid
                container
                alignItems="center"
                justifyContent="center"
                py={2}
              >
                <Grid item container md={12} xs={12} justifyContent="center">
                  <Typography
                    sx={{ color: "white" }}
                    className="text-center md:text-left"
                    variant="primary"
                    color="black"
                  >
                    Your code for the {CONFIG.APP_NAME} is {data?.user_code}
                  </Typography>
                </Grid>
                <Grid
                  item
                  container
                  md={12}
                  xs={12}
                  justifyContent="center"
                  mt={2}
                >
                  <Button
                    onClick={() => {
                      window.location.href = link;
                    }}
                    variant="contained"
                    sx={[
                      {
                        color: "white",
                        textTransform: "lowercase",
                        textDecoration: "underline",
                      },
                      {
                        "&:hover": {
                          color: "black",
                          textTransform: "lowercase",
                          textDecoration: "underline",
                        },
                      },
                    ]}
                  >
                    {data?.user_code ? (
                      link
                    ) : (
                      <CircularProgress style={{ color: "white" }} />
                    )}
                  </Button>
                  <Grid
                    item
                    container
                    md={12}
                    xs={12}
                    justifyContent="center"
                    mt={2}
                  >
                    <Button
                      onClick={getQRCode}
                      variant="contained"
                      sx={[
                        {
                          color: "white",
                        },
                        {
                          "&:hover": {
                            color: "black",
                          },
                        },
                      ]}
                    >
                      {data?.user_code ? (
                        <span>
                          Generate QR <QrCodeIcon />
                        </span>
                      ) : (
                        <CircularProgress style={{ color: "white" }} />
                      )}
                    </Button>
                  </Grid>
                  <Grid
                    item
                    container
                    md={12}
                    xs={12}
                    justifyContent="center"
                    mt={2}
                  >
                    {qrCode && <img src={qrCode} />}
                  </Grid>
                  <Grid
                    item
                    container
                    md={12}
                    xs={12}
                    justifyContent="center"
                    mt={2}
                  >
                    {qrCode && (
                      <Button
                        variant="contained"
                        color="success"
                        href={qrCode}
                        download="qrcode.png"
                      >
                        Download
                      </Button>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Grid>
        <Grid item md={8} sx={1}>
          <Box
            sx={{
              width: "100%",
              maxWidth: 1000,
              bgcolor: "#grey",
              borderRadius: "0.8%",
            }}
          >
            <Grid
              container
              rowSpacing={{ xs: 1, sm: 2, md: 3 }}
              columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            >
              <Grid item container xs={12} sm={12} md={6} direction="column">
                <CustomTextField
                  label={
                    <div>
                      Email
                      <Tooltip
                        title="Enter Your Email Here"
                        animate
                        shrink
                        placement="right-end"
                      >
                        <IconButton size="large">
                          <QuestionMarkIcon
                            style={{
                              fontSize: "15px",
                              color: "#1976d2",
                              marginBottom: "5px",
                            }}
                          />
                        </IconButton>
                      </Tooltip>
                    </div>
                  }
                  name="email"
                  type={"email"}
                  value={data?.email}
                  handleChange={handleChange}
                ></CustomTextField>

                <CustomTextField
                  label={
                    <div>
                      Username
                      <Tooltip
                        title="Enter Your Username Here"
                        animate
                        shrink
                        placement="right-end"
                      >
                        <IconButton size="large">
                          <QuestionMarkIcon
                            style={{
                              fontSize: "15px",
                              color: "#1976d2",
                              marginBottom: "5px",
                            }}
                          />
                        </IconButton>
                      </Tooltip>
                    </div>
                  }
                  name="username"
                  type={"text"}
                  value={data?.username}
                  handleChange={handleChange}
                ></CustomTextField>
                <CustomTextField
                  label={
                    <div>
                      Display name
                      <Tooltip
                        title="Enter Your Name Here"
                        animate
                        shrink
                        placement="right-end"
                      >
                        <IconButton size="large">
                          <QuestionMarkIcon
                            style={{
                              fontSize: "15px",
                              color: "#1976d2",
                              marginBottom: "5px",
                            }}
                          />
                        </IconButton>
                      </Tooltip>
                    </div>
                  }
                  name="display_name"
                  type={"text"}
                  value={data?.display_name}
                  handleChange={handleChange}
                ></CustomTextField>
                <CustomTextField
                  label={
                    <div>
                      Facebook Url
                      <Tooltip
                        title="Enter Your Facebook Url Here"
                        animate
                        shrink
                        placement="right-end"
                      >
                        <IconButton size="large">
                          <QuestionMarkIcon
                            style={{
                              fontSize: "15px",
                              color: "#1976d2",
                              marginBottom: "5px",
                            }}
                          />
                        </IconButton>
                      </Tooltip>
                    </div>
                  }
                  name="facebook_url"
                  type={"text"}
                  value={data?.facebook_url}
                  handleChange={handleChange}
                ></CustomTextField>

                <CustomTextField
                  label={
                    <div>
                      Upi Id
                      <Tooltip
                        title="Enter Your Upi Id Here"
                        animate
                        shrink
                        placement="right-end"
                      >
                        <IconButton size="large">
                          <QuestionMarkIcon
                            style={{
                              fontSize: "15px",
                              color: "#1976d2",
                              marginBottom: "5px",
                            }}
                          />
                        </IconButton>
                      </Tooltip>
                    </div>
                  }
                  name="upi_id"
                  type={"text"}
                  value={data?.upi_id}
                  handleChange={handleChange}
                ></CustomTextField>
                <CustomTextField
                  label={
                    <div>
                      LinkedIn Url
                      <Tooltip
                        title="Enter Your LinkedIn Url Here"
                        animate
                        shrink
                        placement="right-end"
                      >
                        <IconButton size="large">
                          <QuestionMarkIcon
                            style={{
                              fontSize: "15px",
                              color: "#1976d2",
                              marginBottom: "5px",
                            }}
                          />
                        </IconButton>
                      </Tooltip>
                    </div>
                  }
                  name="linkedin_url"
                  type={"text"}
                  value={data?.linkedin_url}
                  handleChange={handleChange}
                ></CustomTextField>
                <CustomTextField
                  label={
                    <div>
                      Redirect Link
                      <Tooltip
                        title="Enter Your Redirect Url Here"
                        animate
                        shrink
                        placement="right-end"
                      >
                        <IconButton size="large">
                          <QuestionMarkIcon
                            style={{
                              fontSize: "15px",
                              color: "#1976d2",
                              marginBottom: "5px",
                            }}
                          />
                        </IconButton>
                      </Tooltip>
                    </div>
                  }
                  name="redirect_url"
                  type={"text"}
                  value={data?.redirect_url}
                  handleChange={handleChange}
                ></CustomTextField>
                <div style={{ marginTop: "30px" }}>
                  <Autocomplete
                    value={value}
                    onChange={(event, newValue) => {
                      setValue(newValue);
                    }}
                    multiple
                    id="tags-filled"
                    options={tagsList.map((tag) => tag)}
                    freeSolo
                    renderTags={(selected) => (
                      <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                        {selected.map((item, id) => (
                          <Chip
                            key={item}
                            label={item}
                            onDelete={() => removeItem(id)}
                          />
                        ))}
                      </Box>
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        focused
                        variant="standard"
                        label={
                          <div>
                            Profile Tags
                            <Tooltip
                              title="Click On Profile Tags or Enter another Tags"
                              animate
                              shrink
                              placement="right-end"
                            >
                              <IconButton size="large">
                                <QuestionMarkIcon
                                  style={{
                                    fontSize: "15px",
                                    color: "#1976d2",
                                    marginBottom: "5px",
                                  }}
                                />
                              </IconButton>
                            </Tooltip>
                          </div>
                        }
                        name="profile_tags"
                        type={"text"}
                        value={value}
                        placeholder="Search"
                      />
                    )}
                  />
                </div>

                <div style={{ marginTop: "20px" }}>
                  <Autocomplete
                    value={val}
                    onChange={(event, newVal) => {
                      setVal(newVal);
                    }}
                    multiple
                    id="tags-filled"
                    options={question.map((tag) => tag)}
                    freeSolo
                    renderTags={(selected) => (
                      <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                        {selected.map((item) => (
                          <Chip key={item} label={item} />
                        ))}
                      </Box>
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        focused
                        variant="standard"
                        label={
                          <div>
                            Question Tags
                            <Tooltip
                              title="Click On Question Tags"
                              animate
                              shrink
                              placement="right-end"
                            >
                              <IconButton size="large">
                                <QuestionMarkIcon
                                  style={{
                                    fontSize: "15px",
                                    color: "#1976d2",
                                    marginBottom: "5px",
                                  }}
                                />
                              </IconButton>
                            </Tooltip>
                          </div>
                        }
                        name="question_tags"
                        type={"text"}
                        value={val}
                        placeholder="Search"
                      />
                    )}
                  />
                </div>
              </Grid>

              <Grid item container xs={12} sm={12} md={6} direction="column">
                {/* <CustomTextField label="Password" name="password" type={'password'} value={data?.password} handleChange={handleChange}></CustomTextField> */}
                <CustomTextField
                  label={
                    <div>
                      Youtube Link
                      <Tooltip
                        title="Enter Your Youtube Link Here"
                        animate
                        shrink
                        placement="right-end"
                      >
                        <IconButton size="large">
                          <QuestionMarkIcon
                            style={{
                              fontSize: "15px",
                              color: "#1976d2",
                              marginBottom: "5px",
                            }}
                          />
                        </IconButton>
                      </Tooltip>
                    </div>
                  }
                  name="youtube_link"
                  type={"text"}
                  value={data?.youtube_link}
                  handleChange={handleChange}
                ></CustomTextField>
                <CustomTextField
                  label={
                    <div>
                      Description
                      <Tooltip
                        title="Enter Your Description Here"
                        animate
                        shrink
                        placement="right-end"
                      >
                        <IconButton size="large">
                          <QuestionMarkIcon
                            style={{
                              fontSize: "15px",
                              color: "#1976d2",
                              marginBottom: "5px",
                            }}
                          />
                        </IconButton>
                      </Tooltip>
                    </div>
                  }
                  name="description"
                  type={"text"}
                  value={data?.description}
                  handleChange={handleChange}
                ></CustomTextField>
                <CustomTextField
                  label={
                    <div>
                      Twitter Link
                      <Tooltip
                        title="Enter Your Twitter Link Here"
                        animate
                        shrink
                        placement="right-end"
                      >
                        <IconButton size="large">
                          <QuestionMarkIcon
                            style={{
                              fontSize: "15px",
                              color: "#1976d2",
                              marginBottom: "5px",
                            }}
                          />
                        </IconButton>
                      </Tooltip>
                    </div>
                  }
                  name="twitter_url"
                  type={"text"}
                  value={data?.twitter_url}
                  handleChange={handleChange}
                ></CustomTextField>
                <CustomTextField
                  label={
                    <div>
                      Instagram Link
                      <Tooltip
                        title="Enter Your Instagram Link Here"
                        animate
                        shrink
                        placement="right-end"
                      >
                        <IconButton size="large">
                          <QuestionMarkIcon
                            style={{
                              fontSize: "15px",
                              color: "#1976d2",
                              marginBottom: "5px",
                            }}
                          />
                        </IconButton>
                      </Tooltip>
                    </div>
                  }
                  name="instagram_url"
                  type={"text"}
                  value={data?.instagram_url}
                  handleChange={handleChange}
                ></CustomTextField>
                <CustomTextField
                  label={
                    <div>
                      Location
                      <Tooltip
                        title="Enter Your Location Here"
                        animate
                        shrink
                        placement="right-end"
                      >
                        <IconButton size="large">
                          <QuestionMarkIcon
                            style={{
                              fontSize: "15px",
                              color: "#1976d2",
                              marginBottom: "5px",
                            }}
                          />
                        </IconButton>
                      </Tooltip>
                    </div>
                  }
                  name="location"
                  type={"text"}
                  value={data?.location}
                  handleChange={handleChange}
                ></CustomTextField>
                <CustomTextField
                  label={
                    <div>
                      Mailing List Label
                      <Tooltip
                        title="Enter Your Mailing List Label Here"
                        animate
                        shrink
                        placement="right-end"
                      >
                        <IconButton>
                          <QuestionMarkIcon
                            style={{
                              fontSize: "15px",
                              color: "#1976d2",
                              marginBottom: "5px",
                            }}
                          />
                        </IconButton>
                      </Tooltip>
                    </div>
                  }
                  name="mailing_label"
                  type={"text"}
                  value={data?.mailing_label}
                  handleChange={handleChange}
                ></CustomTextField>

                <CustomTextField
                  label={
                    <div>
                      Mailng List Placeholder
                      <Tooltip
                        title="Enter Your Mailing List Placeholder Here"
                        animate
                        shrink
                        placement="right-end"
                      >
                        <IconButton size="large">
                          <QuestionMarkIcon
                            style={{
                              fontSize: "15px",
                              color: "#1976d2",
                              marginBottom: "5px",
                            }}
                          />
                        </IconButton>
                      </Tooltip>
                    </div>
                  }
                  name="mailing_placeholder"
                  type={"text"}
                  value={data?.mailing_placeholder}
                  handleChange={handleChange}
                ></CustomTextField>

                <CustomTextField
                  label={
                    <div>
                      Mailng List Button
                      <Tooltip
                        title="Enter Your Mailing List Button Here"
                        animate
                        shrink
                        placement="right-end"
                      >
                        <IconButton size="large">
                          <QuestionMarkIcon
                            style={{
                              fontSize: "15px",
                              color: "#1976d2",
                              marginBottom: "5px",
                            }}
                          />
                        </IconButton>
                      </Tooltip>
                    </div>
                  }
                  name="mailing_button"
                  type={"text"}
                  value={data?.mailing_button}
                  handleChange={handleChange}
                ></CustomTextField>

                <InputNumberWrapper>
                  <Grid
                    item
                    xs={12}
                    my={3}
                    sx={{ flexDirection: "column" }}
                    className="flex justify-center md:justify-start align-center  flex-col "
                  >
                    <InputLabel shrink animated style={{ color: "#1976d2" }}>
                      {
                        <div>
                          WhatsApp Number
                          <Tooltip
                            title="Enter Your Whatsapp number Here"
                            animate
                            shrink
                            placement="right-end"
                          >
                            <IconButton size="large">
                              <QuestionMarkIcon
                                style={{
                                  fontSize: "15px",
                                  color: "#1976d2",
                                  marginBottom: "5px",
                                }}
                              />
                            </IconButton>
                          </Tooltip>
                        </div>
                      }
                    </InputLabel>
                    <PhoneInput
                      defaultCountry="IN"
                      value={mobileNumber}
                      onChange={setMobileNumber}
                    />
                  </Grid>
                </InputNumberWrapper>
                <div
                  style={
                    matches
                      ? { width: "100%" }
                      : { width: "150%", maxWidth: 300 }
                  }
                >
                  <Grid
                    item
                    xs={12}
                    className="flex justify-center lg:justify-end align-center  my-10 "
                  >
                    <Button
                      // fullWidth
                      variant="contained"
                      disabled={authStore.state.ui.isPutUserLoading}
                      sx={{ width: 200, height: 40 }}
                      onClick={submit}
                    >
                      {authStore.state.ui.isPutUserLoading ? (
                        <CircularProgress />
                      ) : (
                        "Submit"
                      )}
                    </Button>
                  </Grid>
                </div>
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </Grid>
    </div>
  );
};

export default observer(UpdateProfile);
