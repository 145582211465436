import React from "react";
import { TextField, Button, CircularProgress } from "@mui/material";
import ApiRequestService from "../../services/api.service";
import {
  InputField,
  ValidateAdmin,
  TextMarginAndCenter,
} from "./CustomStyledComponents";
import { useRootStore } from "../../stores";
import { observer } from "mobx-react-lite";
import { errorToast, successToast } from "../../utils/toast";

function AddUserForm({ setDetailsForm, setData }) {
  const ApiService = new ApiRequestService();
  const [email, setEmail] = React.useState("");
  const [username, setUsername] = React.useState("");
  const [isLoading, setIsLoading] = React.useState(false);
  const [password, setPassword] = React.useState("");
  const { authStore } = useRootStore();

  async function AddUser() {
    if (!email) {
      errorToast("Please enter a valid email");
    } else if (!username) {
      errorToast("Please enter a valid username");
    } else if (!password) {
      errorToast("Please enter a valid password");
    } else {
      setIsLoading(true);
      try {
        let response = await ApiService.postApi(
          `/signup`,
          {
            email: email,
            username: username,
            password: password,
          },
          {}
        );

        const { message, data, status } = response;

        if (status) {
          setData(data);
          console.log(data);
          successToast("Account registered");
          setDetailsForm(true);
        } else {
          errorToast(message);
        }
        // let isValid = await authStore.isUserLoggedIn()
        // if(isValid){
        //   setDetailsForm(true)
        // }
      } catch (e) {
        const { message } = e;
        errorToast(message);
      }
      setIsLoading(false);
    }
  }
  return (
    <ValidateAdmin>
      <TextMarginAndCenter variant="h6">Add User</TextMarginAndCenter>
      <TextMarginAndCenter variant="body1">
        Please enter following details to add a user
      </TextMarginAndCenter>

      <InputField
        onChange={(e) => {
          setUsername(e.target.value);
        }}
        type="text"
        value={username}
        placeholder="Enter Username"
        fullWidth
      />

      <InputField
        onChange={(e) => {
          setEmail(e.target.value);
        }}
        type="email"
        value={email}
        placeholder="Enter Email"
        fullWidth
      />

      <TextField
        onChange={(e) => {
          setPassword(e.target.value);
        }}
        type="password"
        value={password}
        placeholder="Enter Password"
        fullWidth
      />

      <Button
        disabled={isLoading}
        variant="contained"
        onClick={AddUser}
        style={{ marginTop: "20px" }}
      >
        {" "}
        {isLoading ? <CircularProgress /> : "Add User"}
      </Button>
    </ValidateAdmin>
  );
}

export default observer(AddUserForm);
