import React from "react";
import {
  Typography,
  Button,
  Card,
  Container,
  Grid,
  Box,
  CardContent,
  CardHeader,
} from "@mui/material";
import { successToast } from "../../utils/toast";


function PaymentBox() {
  const content = {
    badge: process.env.REACT_APP_NAME,
    "header-p1": "Subscribe",
    "header-p2": "to unlock all premium features",
    description: "Please choose a plan according to your needs",
    option1: "Monthly",
    option2: "Annual",
    "01_title": "Lorem ipsum",
    "01_price": "20₹",
    "01_suffix": " / mo",
    "01_benefit1": "3 Emails",
    "01_benefit2": "1 Database",
    "01_benefit3": "Unlimited Domains",
    "01_benefit4": "10 GB Storage",
    "01_primary-action": "Select plan",
    "01_secondary-action": "Learn more",
    "02_title": "Starter",
    "02_price": "20₹",
    "02_suffix": " / mo",
    "02_benefit1": "Increase number of meeting time to 5",
    "02_benefit2": "No profile customization",
    "02_benefit3": "Customer support",
    "02_benefit4": "25 GB Storage",
    "02_primary-action": "Subscribe!",
    "02_secondary-action": "Learn more",
    "03_title": "Premium",
    "03_price": "100₹",
    "03_suffix": " / mo",
    "03_benefit1": "Unlimited number of meeting time",
    "03_benefit2": "100% profile customization",
    "03_benefit3": "Dedicated Customer support",
    "03_benefit4": "50 GB Storage",
    "03_primary-action": "Subscribe!",
    "03_secondary-action": "Learn more",
  };

  const [paymentLoading, setpaymentloading] = React.useState(false);

  const paymentHandler = async (type) => {
    setpaymentloading(true);

    let subscriptionData = await fetch(
      `${process.env.REACT_APP_PUBLIC_URL}create-subscription`,
      {
        // Adding method type
        method: "POST",

        // Adding body or contents to send
        body: JSON.stringify({
          plan_id:
            type === 1
              ? process.env.REACT_APP_RAZOR_PLAN_ID_1
              : process.env.REACT_APP_RAZOR_PLAN_ID_2,
        }),

        // Adding headers to the request
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
      }
    );
    let subscriptionObj = await subscriptionData.json();
    console.log(subscriptionObj, "subscriptionData");
    var options2 = {
      key: process.env.REACT_APP_RAZOR_PAY_KEY_ID,
      subscription_id: subscriptionObj.subscriptionId,
      name: process.env.REACT_APP_NAME,
      description: "Unlock premium features",
      handler: function (response) {
        console.log(response);
        successToast("Payment successful!!")

        setpaymentloading(false);
        // alert(response.razorpay_payment_id),
        // alert(response.razorpay_subscription_id),
        // alert(response.razorpay_signature);
      },
      notes: {
        note_key_1: "Tea. Earl Grey. Hot",
        note_key_2: "Make it so.",
      },
      modal: {
        ondismiss: function () {
          setpaymentloading(false);
        },
      },
      theme: {
        color: "#F37254",
      },
    };
    const rzp1 = new window.Razorpay(options2);
    rzp1.open();
  };
  return (
    <section>
      <Container maxWidth="lg" style={{ background: "rgba(255,255,255,0.5)" , padding:"30px 10px"}}>
        <Box py={8} textAlign="center">
          <Box mb={3}>
            <Container maxWidth="sm">
              <Typography variant="overline" color="textSecondary">
                {content["badge"]}
              </Typography>
              <Typography variant="h3" component="h2" gutterBottom={true}>
                <Typography variant="h3" component="span" color="primary">
                  {content["header-p1"]}{" "}
                </Typography>
                <Typography variant="h3" style={{color:"black"}} component="span">
                  {content["header-p2"]}
                </Typography>
              </Typography>
              <Typography
                variant="subtitle1"
                color="textSecondary"
                paragraph={true}
              >
                {content["description"]}
              </Typography>

              {/* <div>
                <Typography variant="subtitle1" component="span">{content['option1']}</Typography>
                &nbsp; <Switch name="checkbox" color="primary" checked={state.checkbox} onChange={handleChange} /> &nbsp;
                <Typography variant="subtitle1" component="span">{content['option2']}</Typography>
              </div> */}
            </Container>
          </Box>
          <Grid container spacing={3} justifyContent="center">
            <Grid item xs={12} md={4}>
              <Card variant="outlined">
                <CardHeader title={content["02_title"]}></CardHeader>
                <CardContent>
                  <Box px={1}>
                    <Typography variant="h3" component="h2" gutterBottom={true}>
                      {content["02_price"]}
                      <Typography
                        variant="h6"
                        color="textSecondary"
                        component="span"
                      >
                        {content["02_suffix"]}
                      </Typography>
                    </Typography>
                    <Typography
                      color="textSecondary"
                      variant="subtitle1"
                      component="p"
                    >
                      {content["02_benefit1"]}
                    </Typography>
                    <Typography
                      color="textSecondary"
                      variant="subtitle1"
                      component="p"
                    >
                      {content["02_benefit2"]}
                    </Typography>
                    <Typography
                      color="textSecondary"
                      variant="subtitle1"
                      component="p"
                    >
                      {content["02_benefit3"]}
                    </Typography>
                    <Typography
                      color="textSecondary"
                      variant="subtitle1"
                      component="p"
                      paragraph={true}
                    >
                      {content["02_benefit4"]}
                    </Typography>
                  </Box>
                  <Button
                    disabled={paymentLoading}
                    onClick={() => {
                      paymentHandler(1);
                    }}
                    variant="contained"
                    color="primary"
                  >
                    {content["02_primary-action"]}
                  </Button>
                  {/* <Box mt={2}>
                    <Link href="#" color="primary">{content['03_secondary-action']}</Link>
                  </Box> */}
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} md={4}>
              <Card variant="outlined">
                <CardHeader title={content["03_title"]}></CardHeader>
                <CardContent>
                  <Box px={1}>
                    <Typography variant="h3" component="h2" gutterBottom={true}>
                      {content["03_price"]}
                      <Typography
                        variant="h6"
                        color="textSecondary"
                        component="span"
                      >
                        {content["03_suffix"]}
                      </Typography>
                    </Typography>
                    <Typography
                      color="textSecondary"
                      variant="subtitle1"
                      component="p"
                    >
                      {content["03_benefit1"]}
                    </Typography>
                    <Typography
                      color="textSecondary"
                      variant="subtitle1"
                      component="p"
                    >
                      {content["03_benefit2"]}
                    </Typography>
                    <Typography
                      color="textSecondary"
                      variant="subtitle1"
                      component="p"
                    >
                      {content["03_benefit3"]}
                    </Typography>
                    <Typography
                      color="textSecondary"
                      variant="subtitle1"
                      component="p"
                      paragraph={true}
                    >
                      {content["03_benefit4"]}
                    </Typography>
                  </Box>
                  <Button
                    disabled={paymentLoading}
                    onClick={() => {
                      paymentHandler(2);
                    }}
                    variant="contained"
                    color="primary"
                  >
                    {content["03_primary-action"]}
                  </Button>
                  {/* <Box mt={2}>
                    <Link href="#" color="primary">{content['03_secondary-action']}</Link>
                  </Box> */}
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </section>
  );
}

export default PaymentBox;
