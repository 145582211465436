import React from "react";
import { CircularProgress } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useRootStore } from "../stores";

//google auth
function GoogleAuth() {
  const { authStore } = useRootStore();
  const navigate = useNavigate();

  const googleSignIn = async () => {
    await authStore.onGoogleSignIn();
    navigate("/dashboard", { replace: true });
  };

  React.useEffect(() => {
    googleSignIn();
  }, []);

  return (
    <div className="my-7 flex justify-center align-center">
      <CircularProgress />
    </div>
  );
}

export default GoogleAuth;
