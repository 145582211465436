export const getState = (key) => {
  try {
    const serializedState = localStorage.getItem(key);
    if (!serializedState) {
      return undefined;
    }
    return key === 'token' ? serializedState : JSON.parse(serializedState);
  } catch (err) {
    return undefined;
  }
};

export const setState = (key, data) => {
  try {
    const serializedState = key === 'token' ? data : JSON.stringify(data);
    localStorage.setItem(key, serializedState);
  } catch (e) {
    console.log(e);
    // ignore write errors
  }
};

export const removeState = (key) => {
  try {
    localStorage.removeItem(key);
  } catch {
    // ignore write errors
  }
};

export const clearState = () => {
  localStorage.clear();
};
