import React from "react";
import HomeHeader from "./HomeHeader";
import PaymentBox from "../Components/PaymentBox";
import { observer } from "mobx-react-lite";
import { useRootStore } from "../../stores";
import Footer from "../Components/Footer";
const Home = ({ footerModal, setFooterModal, content, setContent,privacyActive,setPrivacyActive }) => {
  const { authStore } = useRootStore();
  const isLoggedIn = React.useMemo(
    () => authStore.isUserLoggedIn(),
    [authStore.state]
  );

  return (
    <div className="bg-green-800 min-h-screen pt-10 flex justify-center align-center flex-col">
      <HomeHeader></HomeHeader>
      {isLoggedIn && <PaymentBox />}
      <Footer
        content={content}
        setContent={setContent}
        setFooterModal={setFooterModal}
        footerModal={footerModal}
        privacyActive={privacyActive} setPrivacyActive={setPrivacyActive}

      />
    </div>
  );
};

export default observer(Home);
