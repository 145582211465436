import React from "react";
import { ParentBox } from "./CustomStyledComponents";
import ValidationForm from "./ValidationForm";
import AddUserForm from "./AddUserForm";
import AddUserDetails from "./AddUserDetails";

function SecretAdmin() {
  const [isAdmin, setIsAdmin] = React.useState(false);
  const [showDetailsForm, setDetailsForm] = React.useState(false);
  const [data, setData] = React.useState(null);
  return (
    <ParentBox style={{ height: showDetailsForm ? "auto" : "100vh" }}>
      {!isAdmin && <ValidationForm setIsAdmin={setIsAdmin} />}
      {isAdmin && !showDetailsForm && (
        <AddUserForm setData={setData} setDetailsForm={setDetailsForm} />
      )}
      {showDetailsForm && <AddUserDetails userInfo={data} />}
    </ParentBox>
  );
}

export default SecretAdmin;
