import ApiRequestService from "./api.service";

const ApiService = new ApiRequestService();

export const userLogin = (user) => {
  const requestData = {
    email: user.email,
    password: user.password,
  };

  return ApiService.postApi(`/login`, requestData, {});
};

export const userSignup = (user) => {
  const requestData = {
    username: user.username,
    email: user.email,
    password: user.password,
    isValidTill: user.isValidTill,
    user_code: user.user_code,
    description: user.description,
    facebook_url: user.facebook_url,
    instagram_url: user.instagram_url,
    twitter_url: user.twitter_url,
    linkedin_url: user.linkedin_url,
    whatsapp_url: user.whastapp_url,
    redirect_url: user.redirect_url,
    isRedirected: user.isRedirected,
    isAdmin: user.isAdmin,
  };

  return ApiService.postApi(`/signup`, requestData, {});
};

export const userSignupLinkedin = (user) => {
  console.log(user, "usersignuplinkedin frontend");
  const requestData = {
    username: user.username,
    email: user.email,
    password: user.password,
    user_code: user.user_code,
    description: user.description,
    facebook_url: user.facebook_url,
    instagram_url: user.instagram_url,
    twitter_url: user.twitter_url,
    linkedin_url: user.linkedin_url,
    whatsapp_url: user.whastapp_url,
    redirect_url: user.redirect_url,
    isRedirected: user.isRedirected,
    isAdmin: user.isAdmin,
  };

  return ApiService.postApi(`/oauth`, requestData, {});
};

// google sign in api
export const userGoogleSignIn = () => {
  return ApiService.getApi(`/google`);
};

export const userEventSignup = (user) => {
  const requestData = {
    username: user.username,
    email: user.email,
    password: user.password,
    user_code: user.user_code,
  };

  return ApiService.postApi(`/register-for-event`, requestData, {});
};
