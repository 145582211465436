import ApiRequestService from "./api.service";
import { contentTypes } from "./utils";

const ApiService = new ApiRequestService();

export const getEvent = (id) => {
  return ApiService.getApi(`/get-event/${id}`, { ...contentTypes.json }, {});
};

export const createEvent = (body) => {
  return ApiService.postApi(`/create-event`, body, { ...contentTypes.json });
};

export const registerForEvent = (body) => {
  return ApiService.postApi(`/register-for-event`, body, { ...contentTypes.json });
};