import Axios from "axios";
import CONFIG from "../config/config.env";

class ApiRequestService {
  constructor(baseURL) {
    this.baseURL = baseURL || CONFIG.PUBLIC_URL;
    this.axiosService = Axios.create({
      baseURL: this.baseURL,
      // timeout: 60000,
      headers: {
        "content-type": "application/json",
        accept: "application/json",
      },
    });
  }

  getApi(path, headers = {}, params = {}) {
    return new Promise((resolve) => {
      this.axiosService
        .get(path, {
          withCredentials: true,
          headers,
          params,
        })
        .then((res) => {
          console.log("GET API Response: ", res);
          resolve({
            message: res.data.message,
            status: true,
            data: res.data,
          });
        })
        .catch((error) => {
          console.log("GET API Error: ", error);
          resolve({
            message: error?.response?.data?.message
              ? error?.response?.data?.message
              : error?.response?.data,
            status: false,
          });
        })
        .then(() => {
          // here the code which should be excuted always
        });
    });
  }

  postApi(path, apiData, headers = {}) {
    return new Promise((resolve) => {
      this.axiosService
        .post(path, apiData, {
          headers,
        })
        .then((res) => {
          console.log("POST API Response: ", res);
          resolve({
            message: res.data.message,
            status: true,
            data: res.data,
          });
        })
        .catch((error) => {
          console.log("POST API Error: ", error);
          resolve({
            message: error?.response?.data?.message
              ? error?.response?.data?.message
              : error?.response?.data,
            status: false,
          });
        })
        .then(() => {
          // here the code which should be excuted always
        });
    });
  }

  deleteApi(path, headers = {}) {
    return new Promise((resolve) => {
      this.axiosService
        .delete(path, { headers })
        .then((res) => {
          console.log("DELETE API Response: ", res);
          resolve({
            message: res.data.message,
            status: true,
            data: res.data,
          });
        })
        .catch((error) => {
          console.log("DELETE API Error: ", error);
          resolve({
            message: error?.response?.data?.message
              ? error?.response?.data?.message
              : error?.response?.data,
            status: false,
          });
        })
        .then(() => {
          // here the code which should be excuted always
        });
    });
  }

  putApi(path, apiData, headers = {}) {
    return new Promise((resolve) => {
      this.axiosService
        .put(path, apiData, {
          headers,
        })
        .then((res) => {
          console.log("PUT API Response: ", res);
          resolve({
            message: res.data.message,
            status: true,
            data: res.data,
          });
        })
        .catch((error) => {
          console.log("PUT API Error: ", error);
          resolve({
            message: error?.response?.data?.message
              ? error?.response?.data?.message
              : error?.response?.data,
            status: false,
          });
        })
        .then(() => {
          // here the code which should be excuted always
        });
    });
  }
}
export default ApiRequestService;
