import { getState } from './localStorage';

export const contentTypes = {
  multipart: {
    'Content-Type': 'multipart/form-data'
  },
  json: {
    'Content-Type': 'application/json'
  }
};

export const authHeader = (token) => ({ Authorization: token || getState('token') });
