import React from "react";
import { Grid, Button, Divider } from "@mui/material";
import LoginHeader from "./LoginHeader";
import { useState, useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";
import CustomTextField from "../Components/CustomTextField";
import CustomPasswordField from "../Components/CustomPasswordField";
import { useRootStore } from "../../stores";
import CONFIG from "../../config/config.env";
import { LINKEDIN_URL, queryToObject } from "../../services/helperLinkedin";
import LinkedinLogo from "../../images/LI-In-Bug.png";
import { GOOGLE_URL } from "../../services/helperGoogle";
import { Google } from "@mui/icons-material";

const Login = () => {
  const { authStore } = useRootStore();
  const [data, setData] = useState({
    email: "",
    password: "",
  });
  const navigate = useNavigate();

  useEffect(() => {
    const isLoggedIn = authStore.isUserLoggedIn();
    if (isLoggedIn) {
      authStore.reset();
    }
  }, [authStore.state]);

  const handleChange = (event) => {
    const { value, name } = event.target;
    setData({ ...data, [name]: value });
  };

  const submit = async (event) => {
    event.preventDefault();
    console.log(data);
    await authStore.onSignIn(data);
    navigate("/dashboard", { replace: true });
  };

  return (
    <div style={{ padding: 30, background: "white" }}>
      <div>
        <LoginHeader></LoginHeader>
        <Divider sx={{ background: "white" }} />
        <div className="p-3">
          <h2 className="text-xl md:text-3xl font-bold text-center mt-5">
            Log in to your {CONFIG.APP_NAME}
          </h2>
          <Grid
            container
            direction={"column"}
            justifyContent={"center"}
            alignItems={"center"}
            mt={3}
          >
            <div className="my-3 flex justify-center align-center">
              <CustomTextField
                label="Enter Email"
                name="email"
                type={"email"}
                value={data.email}
                handleChange={handleChange}
              ></CustomTextField>
            </div>
            <div className="my-3 flex justify-center align-center">
              <CustomPasswordField
                label="Enter Password"
                name="password"
                value={data.password}
                handleChange={handleChange}
              ></CustomPasswordField>
            </div>
            <div className="my-7 flex justify-center align-center">
              <Button
                variant="contained"
                size="large"
                // sx={{ width: 200, height: 40 }}
                onClick={submit}
              >
                Login
              </Button>
            </div>
            <div className="my-3 flex justify-center align-center">
              <a href={LINKEDIN_URL}>
                <Button variant="outlined" size="large">
                  {" "}
                  Login with LinkedIn
                  <div type="submit" style={{ height: "40px", width: "40px" }}>
                    <img
                      className="ml-2"
                      src={LinkedinLogo}
                      alt={"LinkedIn authentification"}
                    />
                  </div>
                </Button>
              </a>
            </div>
            <div className="my-3 flex justify-center align-center">
              {/* google sign in button */}
              <a href={GOOGLE_URL}>
                <Button
                  variant="outlined"
                  size="large"
                  style={{ height: "56px" }}
                >
                  Signin with Google
                  <Google style={{ marginLeft: "0.25rem" }} />
                </Button>
              </a>
            </div>
            <div className="my-2 flex justify-center align-center text-sm">
              Dont have an account?
              <span className="text-blue-500 underline mx-1">
                {" "}
                <Link to="/register">Sign up</Link>
              </span>
            </div>
          </Grid>
        </div>
      </div>
    </div>
  );
};

export default Login;
