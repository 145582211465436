import React from "react";
import styled from "@emotion/styled";
import CloseIcon from "@mui/icons-material/Close";
import {
  Card,
  Typography,
  Divider,
  Button,
  ListItem,
  InputLabel,
  MenuItem,
  FormControl,
  Select,
} from "@mui/material";
import { useRootStore } from "../../stores";
import { errorToast } from "../../utils/toast";

const MainModalBox = styled.div`
  position: absolute;
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.7);
  overflow: hidden;
  z-index: 100;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const CardTitleBox = styled.div`
  padding: 15px 10px;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const AddTimeForm = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 50px;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const AddPadding = styled.div`
  padding: 20px;
  width: calc(100% - 40%);
  margin: auto;
  @media (max-width: 768px) {
    width: calc(100% - 10%);
  }
`;
const CenterBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;
const FormCard = styled(Card)`
  width: calc(100% - 40%);
  margin: auto;

  @media (max-width: 768px) {
    width: calc(100% - 10%);
  }
`;

function Modal({ isModalOpen, setIsModalOpen, userInfo }) {
  const [user, setUser] = React.useState("");
  const { authStore } = useRootStore();
  const DayArray = [
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
    "Sunday",
  ];
  React.useEffect(() => {
    const user = JSON.parse(localStorage.getItem("userData"));
    console.log("modal", user);
    console.log(user);
    if (user) {
      setUser(user);
      if (user.hasOwnProperty("meeting_time")) {
        let arr = user.meeting_time ? user.meeting_time.split(",") : [];
        setTimeArr(arr);
      }
    }
  }, []);
  async function handleSubmit() {
    await authStore.onCreateMeetingTimings(user.username, {
      meetTimings: timeArr ? timeArr.join(",") : "",
    });
  }
  const Time = [
    "00:00",
    "00:30",
    "01:00",
    "01:30",
    "02:00",
    "02:30",
    "03:00",
    "03:30",
    "04:00",
    "04:30",
    "05:00",
    "05:30",
    "06:00",
    "06:30",
    "07:00",
    "07:30",
    "08:00",
    "08:30",
    "09:00",
    "09:30",
    "10:00",
    "10:30",
    "11:00",
    "11:30",
    "12:00",
    "12:30",
    "13:00",
    "13:30",
    "14:00",
    "14:30",
    "15:00",
    "15:30",
    "16:00",
    "16:30",
    "17:00",
    "17:30",
    "18:00",
    "18:30",
    "19:00",
    "19:30",
    "20:00",
    "20:30",
    "21:00",
    "21:30",
    "22:00",
    "22:30",
    "23:00",
    "23:30",
  ];

  const [time, setTime] = React.useState("");
  const [day, setDay] = React.useState("");

  const [timeArr, setTimeArr] = React.useState([]);

  const handleDayChange = (event) => {
    setDay(event.target.value);
  };
  const handleTimeChange = (event) => {
    setTime(event.target.value);
  };
  function CardHeader() {
    return (
      <CardTitleBox>
        <Typography variant="h6">{"Add Meeting Time"}</Typography>
        <CloseIcon
          onClick={() => {
            setIsModalOpen(false);
          }}
          style={{ color: "black", fontSize: "30px", cursor: "pointer" }}
        />
      </CardTitleBox>
    );
  }

  React.useEffect(() => {
    window.scrollTo(0, 0);
    document.body.style.overflow = "hidden";

    return () => {
      document.body.style.overflow = "auto";
    };
  }, []);
  return (
    <MainModalBox>
      <FormCard variant="outlined">
        <CardHeader />
        <Divider />

        <Typography
          style={{ padding: "5px 10px", fontWeight: 400, fontSize: "15px" }}
          variant="h6"
        >
          Note: these timings will be used to schedule instant meetings on your
          profile.
        </Typography>

        <AddTimeForm>
          <FormControl fullWidth style={{ padding: "5px" }}>
            <InputLabel id="demo-simple-select-label">Day</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              disabled={
                parseInt(process.env.REACT_APP_NUMBER_OF_MEETING) ===
                timeArr.length
              }
              value={day}
              label="Day"
              onChange={handleDayChange}
            >
              {DayArray.map((el) => {
                return <MenuItem value={el}>{el}</MenuItem>;
              })}
            </Select>
          </FormControl>
          <FormControl fullWidth style={{ padding: "5px" }}>
            <InputLabel id="demo-simple-select-label">Time (24 Hour Format)</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              disabled={
                parseInt(process.env.REACT_APP_NUMBER_OF_MEETING) ===
                timeArr.length
              }
              placeholder={"Select time"}
              value={time}
              label="Time"
              onChange={handleTimeChange}
            >
              {Time.map((el) => {
                return <MenuItem value={el}>{el}</MenuItem>;
              })}
            </Select>
          </FormControl>

          <Button
            variant="contained"
            disabled={
              parseInt(process.env.REACT_APP_NUMBER_OF_MEETING) ===
              timeArr.length
            }
            style={{ padding: "5px" }}
            onClick={() => {
              if (!day) {
                errorToast("Please select day");
              } else if (!time) {
                errorToast("Please select time");
              } else {
                let str = `${day} ${time}`;

                setTimeArr((prev) => [...prev, str]);
                setTime("");
                setDay("");
              }
            }}
          >
            Add
          </Button>
        </AddTimeForm>
        <CenterBox>
          <Button
            variant="contained"
            style={{ background: "green" }}
            onClick={() => {
              handleSubmit();
            }}
          >
            Submit
          </Button>
        </CenterBox>

        {console.log(process.env.REACT_APP_NUMBER_OF_MEETING, timeArr.length)}
        <AddPadding>
          {timeArr.length > 0 &&
            timeArr.map((el, index) => {
              return (
                <ListItem
                  divider
                  alignItems="center"
                  style={{ justifyContent: "space-between" }}
                >
                  <span>{index + 1 + ".  " + el}</span>
                  <span>
                    <CloseIcon
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        setTimeArr((prev) =>
                          prev.filter((elem) => elem !== el)
                        );
                      }}
                    />
                  </span>
                </ListItem>
              );
            })}
        </AddPadding>
      </FormCard>
    </MainModalBox>
  );
}

export default Modal;
