import { Routes, Route, Link, Navigate } from "react-router-dom";
import "./App.css";
import ComingSoon from "./ComingSoon/ComingSoon";
import React from "react";
import Home from "./Pages/Home/Home";
import Temp from "./Pages/Home/Temp";
import Login from "./Pages/Login/Login";
import Register from "./Pages/Login/Register";
import Profile from "./Pages/Profile/Profile";
import UpdateProfile from "./Pages/Profile/UpdateProfile";
import Admin from "./Pages/Admin/Admin";
import { useRootStore } from "./stores";
import { ToastContainer } from "react-toastify";
import Modal from "./Pages/Components/Modal";
import SecretAdmin from "./Pages/SecretAdmin/SecretAdmin";
import GetCode from "./Pages/GetCode/GetCode";
import LinkedinAuth from "./Pages/LinkedinAuth";
import EventRegisteration from "./Pages/Event/RegisterEvent";
import RegisterUserForEvent from "./Pages/Event/RegisterUserForEvent";
import EventPage from "./Pages/Event/EventPage";
import ModalFooter from "./Pages/Components/ModalFooter";
import Footer from "./Pages/Components/Footer";
import { footerText } from "./utils/footerContent";
import GoogleAuth from "./Pages/GoogleAuth";
function App() {
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [footerModal, setFooterModal] = React.useState(false);
  const [content, setContent] = React.useState("");
  const [privacyActive, setPrivacyActive] = React.useState(false);
  return (
    <div className="font-roboto">
      <ToastContainer />
      <Routes>
        <Route
          path="/"
          element={
            <>
              {footerModal && (
                <ModalFooter
                  setFooterModal={setFooterModal}
                  footerModal={footerModal}
                  content={content}
                  privacyActive={privacyActive}
                  setPrivacyActive={setPrivacyActive}
                />
              )}
              <Home
                privacyActive={privacyActive}
                setPrivacyActive={setPrivacyActive}
                setIsModalOpen={setIsModalOpen}
                content={content}
                setContent={setContent}
                setFooterModal={setFooterModal}
                footerModal={footerModal}
              />
            </>
          }
        />
        <Route path="/login" element={<Login />} />
        <Route path="/register" element={<Register />} />
        <Route
          path="/register/events"
          element={<Register isEventUserTrue={true} />}
        />
        <Route path="/register/:user_code" element={<Register />} />
        <Route
          path="/register-event/:event_code"
          element={<RegisterUserForEvent />}
        />
        <Route
          path="/register/events/:user_code"
          element={<Register isEventUserTrue={true} />}
        />
        <Route
          path="/dashboard"
          element={
            <>
              {isModalOpen && (
                <Modal
                  setIsModalOpen={setIsModalOpen}
                  isModalOpen={isModalOpen}
                />
              )}
              <PrivateRoute>
                <UpdateProfile setIsModalOpen={setIsModalOpen} />
              </PrivateRoute>
            </>
          }
        />
        <Route
          path="/admin"
          element={
            <AdminRoute>
              <Admin />
            </AdminRoute>
          }
        />
        <Route path=":user_code" element={<Profile />} />
        <Route exact path="/at/:username" element={<Profile />} />
        <Route
          exact
          path="/at/events/:user_code"
          element={<Profile isEvent={true} />}
        />
        <Route
          exact
          path="/at/event/:username"
          element={<Profile isEvent={true} />}
        />
        <Route path="/registeryourevent" element={<EventRegisteration />} />
        <Route path="/comingSoon" element={<ComingSoon />} />
        <Route path="/oauth" element={<LinkedinAuth />} />
        <Route path="/googleAuth" element={<GoogleAuth />} />
        <Route path="/event-page" element={<EventPage />} />
        <Route
          path="/getCode"
          element={
            <AdminRoute>
              <GetCode />
            </AdminRoute>
          }
        />
        {/* <Route path="/temp" element={<Temp />} />
        <Route path="/secret" element={<SecretAdmin />} /> */}
        <Route path="*" element={<ComingSoon />} />
      </Routes>
    </div>
  );
}

const PrivateRoute = ({ children }) => {
  const { authStore } = useRootStore();
  const isLoggedIn = authStore.isUserLoggedIn();
  const isAdmin = authStore.isAdmin();
  return isLoggedIn ? children : <Navigate to="/login" />;
};

const AdminRoute = ({ children }) => {
  const { authStore } = useRootStore();
  const isLoggedIn = authStore.isUserLoggedIn();
  const isAdmin = authStore.isAdmin();
  return isLoggedIn ? (
    isAdmin ? (
      children
    ) : (
      <Navigate to="/dashboard" />
    )
  ) : (
    <Navigate to="/login" />
  );
};

export default App;
