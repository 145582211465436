import React from "react";
import { Grid, TextField } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

const CustomTextField = (props) => {
  const { name, value, type, label, handleChange,placeholder, my } = props;
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("md"));
  let styleObj = { width: "150%", height: 10, minWidth:"230px" };
  return (
    <div>
      <Grid
        item
        xs={12}
        my={my ?? 3}
        className="flex justify-center md:justify-start align-center"
      >
        <TextField
          focused
          placeholder={placeholder}
          name={name}
          value={value}
          onChange={(e) => handleChange(e)}
          label={label}
          type={type}
          variant="standard"
          sx={matches ? styleObj : { ...styleObj, maxWIdth: 300 }}
        ></TextField>
      </Grid>
    </div>
  );
};

export default CustomTextField;
