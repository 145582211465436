import {
    Avatar,
    Divider,
    Box,
    Table,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TableBody,
    Paper,
    Button,
    CircularProgress,
    Grid
} from "@mui/material";
import { useRootStore } from "../../stores";
import ProfileHeader from "../Profile/ProfileHeader"
import React, { useState, useEffect } from "react";
import { Delete } from "@mui/icons-material";
import CONFIG from "../../config/config.env";
import { deepPurple } from "@mui/material/colors";
import { deleteUser } from "../../services/User";

const Admin = () => {
    const { authStore } = useRootStore();
    const [users, setUsers] = useState();

    const fetchAllUsers = async () => {
        await authStore.onGetAllUsers();
        const data = JSON.parse(localStorage.getItem("users"));
        setUsers(data);
        console.log(users);
    }

    const deleteUser = async (id) => {
        console.log(id)
        await authStore.onDeleteUser(id);
        await fetchAllUsers()
    }

    useEffect(() => {
        fetchAllUsers();
    }, []);

    return (
        <div style={{ padding: 30 }}>
            <div>
                <ProfileHeader></ProfileHeader>
                <div className="p-3">
                    <h2 className="text-xl md:text-3xl font-bold text-center mt-3">
                        Users list
                    </h2>
                </div>
            </div>
            <Box sx={{ flexGrow: 1 }} mt={6}>
                {users ?
                    <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 650}} aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Avatar</TableCell>
                                    <TableCell align="left">Display Name</TableCell>
                                    <TableCell align="left">Username</TableCell>
                                    <TableCell align="left">Usercode</TableCell>
                                    <TableCell align="left">Email</TableCell>
                                    <TableCell align="center">Action</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {users.map((user, index) => (
                                    <>
                                        <TableRow
                                            key={index}
                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                        >
                                            <TableCell component="th" scope="row">
                                                <Avatar
                                                    sx={{ bgcolor: deepPurple[500], width: 60, height: 60 }}
                                                    src={user?.avatar
                                                        ? CONFIG.PUBLIC_URL + user.avatar
                                                        : "/static/img/avatars/default-profile.svg"}
                                                />
                                            </TableCell>
                                            <TableCell align="left">{user.display_name ?? "null"}</TableCell>
                                            <TableCell align="left">{user.username ?? "null"}</TableCell>
                                            <TableCell align="left">{user.user_code ?? "null"}</TableCell>
                                            <TableCell align="left">{user.email ?? "null"}</TableCell>
                                            <TableCell align="center">
                                                <Delete sx={{ color: "#CE2029", cursor: "pointer" }}
                                                    onClick={() => { deleteUser(user.id) }}
                                                />
                                            </TableCell>
                                        </TableRow>
                                    </>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    :
                    <Grid
                        container
                        spacing={1}
                        direction="column"
                        alignItems="center"
                        justifyContent="center"
                        pt={8}
                    >
                        <CircularProgress />
                    </Grid>
                }
            </Box>
        </div>
    )
}

export default Admin