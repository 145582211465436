import React from 'react'
import styled from '@emotion/styled'
import Link from '@mui/material/Link';

import {footerText} from '../../utils/footerContent'
export default function Footer({ footerModal, setFooterModal, content, setContent,privacyActive,setPrivacyActive }) {
    const Footer = styled.div`
    position:absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height:50px;
    background:#f1f5f9;
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-wrap: wrap;
    `;
  return (
    <Footer>
<Link
  component="button"
  variant="body2"
  onClick={() => {
    setContent(footerText.privacyNotice)
    setFooterModal(true)
    setPrivacyActive(true)

  }}
>
  Privacy Notice
</Link><Link
  component="button"
  variant="body2"
  onClick={() => {
    setContent(footerText.termsOfUse)
    setFooterModal(true)
    
    setPrivacyActive(false)
  }}
>
  Terms of use
</Link>

    </Footer>
  )
}
