import React from "react";
import { Grid, Button, Divider } from "@mui/material";
import LoginHeader from "../Login/LoginHeader";
import { useState, useEffect } from "react";
import { useNavigate, useParams, Link } from "react-router-dom";
import CustomTextField from "../Components/CustomTextField";
import CustomPasswordField from "../Components/CustomPasswordField";
import { useRootStore } from "../../stores";
import CONFIG from "../../config/config.env";
import { LINKEDIN_URL } from "../../services/helperLinkedin";
import LinkedinLogo from "../../images/LI-In-Bug.png";

const eventUserTrue = (isEventUserTrue) => {
  return !!isEventUserTrue
};

const RegisterUserForEvent = props => {
  const { event_code } = useParams();
  const { authStore } = useRootStore();
  const [data, setData] = useState();
  const navigate = useNavigate();

  useEffect(() => {
    const isLoggedIn = authStore.isUserLoggedIn();
    if (isLoggedIn) {
      authStore.reset();
    }
  }, [authStore.state]);

  const handleChange = (event) => {
    let { value, name } = event.target;
    if (name == "username" && value) {
      value = value.replace(/\s/g, "").toLowerCase();
    }
    if (event_code) {
      setData({ ...data, event_code: event_code, [name]: value });
    } else {
      setData({ ...data, [name]: value });
    }
  };

  const submit = async (event) => {
    event.preventDefault();
    console.log(data)
    let status = await authStore.onUserEventSignUp(data);
    if(status){
      navigate("/event-page", { replace: true });
    }
  };

  return (
    <div style={{ padding: 30 }}>
      <div>
        <LoginHeader></LoginHeader>

        <Divider sx={{ background: "white" }} />
        <div className="p-3">
          <h2 className="text-xl md:text-3xl font-bold text-center mt-5">
            Register and get your {CONFIG.APP_NAME}
          </h2>
          <div className="mt-10"></div>
          <Grid
            container
            direction={"column"}
            justifyContent={"center"}
            alignItems={"center"}
          >
            <div className="my-3 flex justify-center align-center">
              <CustomTextField
                label="Enter Email"
                name="email"
                type={"email"}
                value={data?.email}
                handleChange={handleChange}
              ></CustomTextField>
            </div>
            <div className="my-3 flex justify-center align-center">
              <CustomTextField
                label="Enter Username"
                name="username"
                type="text"
                value={data?.username}
                handleChange={handleChange}
              ></CustomTextField>
            </div>
            <div className="my-3 flex justify-center align-center">
              <CustomPasswordField
                label="Enter Password"
                name="password"
                value={data?.password}
                handleChange={handleChange}
              ></CustomPasswordField>
            </div>
            <div className="my-7 flex justify-center align-center">
              <Button variant="contained" onClick={submit} size="large">
                Register
              </Button>
            </div>
            <div className="my-3 flex justify-center align-center">OR</div>
            <div className="my-3 flex justify-center align-center">
              <a href={LINKEDIN_URL}>
                <Button variant="outlined" size="large">
                  {" "}
                  Signup with LinkedIn
                  <div type="submit" style={{ height: "40px", width: "40px" }}>
                    <img
                      className="ml-2"
                      src={LinkedinLogo}
                      alt={"LinkedIn authentification"}
                    />
                  </div>
                </Button>
              </a>
            </div>
            <div className="my-2 flex justify-center align-center text-sm">
              Already have an account?
              <span className="text-blue-500 underline mx-1">
                {" "}
                <Link to="/login">Sign in</Link>
              </span>
            </div>
          </Grid>
        </div>
      </div>
    </div>
  );
};

export default RegisterUserForEvent;
