import React from "react";
import { action, observable, makeObservable, makeAutoObservable } from "mobx";
import { getState, setState, clearState } from "../services/localStorage";
import {
  userLogin,
  userSignup,
  userSignupLinkedin,
  userEventSignup,
  userGoogleSignIn,
} from "../services/auth";
import {
  getUser,
  getUserByCode,
  getUserByName,
  putUser,
  postVisitingUserData,
  uploadAvatar,
  getAllUsers,
  deleteUser,
  mailingList,
  meetingListApi,
  generateCode,
} from "../services/User";
import { errorToast, successToast } from "../utils/toast";

export const initialState = {
  /* data key stays in sync with localStorage so store the only values which you want to persist */
  isModalOpen: false,
  userData: null,
  token: null,
  ui: {
    isSignInLoading: false,
    isGetUserLoading: false,
    isPutUserLoading: false,
    isUserVisitingLoading: false,
  },
};

class AuthStore {
  state = {};

  constructor() {
    // localStorage.clear()
    // this.rootStore = rootStore
    /* state hydration from localStorage */
    const userData = getState("userData");
    const token = getState("token");
    const id = getState("id");
    const user_code = getState("user_code");
    const users = getState("users");
    const state = {
      ...initialState,
      userData,
      token,
      id,
      user_code,
      users,
    };
    // makeObservable(this, {
    //   state: observable,
    //   updateState: action,
    //   updateLocalStorage: action,
    //   reset: action,
    // });
    makeAutoObservable(this);
    this.updateState(state);
    this.updateLocalStorage(state);
  }

  isUserLoggedIn = () => {
    const { token } = this.state;
    let isLoggedIn = Boolean(token);
    if (token === "undefined" || token === "null") {
      isLoggedIn = false;
    }
    return isLoggedIn;
  };

  onLogout = () => {
    localStorage.clear();
  };

  onModalOpenhandler = () => {
    this.updateState({
      isModalOpen: true,
    });
  };

  getModalValue = () => {
    return this.state.isModalOpen;
  };

  onModalClosehandler = () => {
    this.updateState({
      isModalOpen: false,
    });
  };

  onSignIn = async (params) => {
    localStorage.clear();
    const { ui } = this.state;
    this.updateState({ ui: { ...ui, isSignInLoading: true } });
    try {
      const response = await userLogin(params);
      const { message, status, data } = response;

      if (status) {
        const { token, id, user_code } = data;
        this.updateState({
          userData: data,
          status,
          message,
          token,
          id,
          user_code,
          ui: { ...ui, isSignInLoading: false },
        });
        this.updateLocalStorage();
        successToast("Login Successful");
      } else {
        errorToast(message);
      }
    } catch (error) {
      const { message } = error;
      this.updateState({
        message: "Email and Password entered are invalid",
      });

      errorToast(message);
    }
    this.updateState({ ui: { ...ui, isSignInLoading: false } });
  };

  onSignUp = async (params) => {
    const { ui } = this.state;
    console.log(params, "This is params");

    this.updateState({ ui: { ...ui, isSignInLoading: true } });
    try {
      const response = await userSignup(params);
      const { message, data, status } = response;

      if (status) {
        const { token, id, user_code } = data;
        this.updateState({
          userData: data,
          token,
          status,
          message,
          id,
          user_code,
          ui: { ...ui, isSignInLoading: false },
        });
        this.updateLocalStorage();
        successToast("Account registered");
      } else {
        errorToast(message);
      }
    } catch (error) {
      const { message } = error;
      errorToast(message);
    }
    this.updateState({ ui: { ...ui, isSignInLoading: false } });
  };

  onUserEventSignUp = async (params) => {
    const { ui } = this.state;
    console.log(params, "This is params");

    this.updateState({ ui: { ...ui, isSignInLoading: true } });
    try {
      const response = await userEventSignup(params);
      const { message, data, status } = response;

      if (status) {
        const { token, id, user_code } = data.user;
        this.updateState({
          userData: data,
          token,
          status,
          message,
          id,
          user_code,
          ui: { ...ui, isSignInLoading: false },
        });
        this.updateLocalStorage();
        console.log(data);
        successToast(message);
        return status;
      } else {
        errorToast(message);
      }
    } catch (error) {
      const { message } = error;
      errorToast(message);
    }
    this.updateState({ ui: { ...ui, isSignInLoading: false } });
  };

  onSignUpLinkedin = async (params) => {
    localStorage.clear();
    const { ui } = this.state;
    this.updateState({ ui: { ...ui, isSignInLoading: true } });
    try {
      const response = await userSignupLinkedin(params);
      const { message, status, data } = response;
      console.log(response);

      if (status) {
        const { token, id, user_code } = data.user;
        this.updateState({
          userData: data,
          status,
          message,
          token,
          id,
          user_code,
          ui: { ...ui, isSignInLoading: false },
        });
        this.updateLocalStorage();
        successToast("Login Successful");
      } else {
        errorToast(message);
      }
    } catch (error) {
      const { message } = error;
      this.updateState({
        message: "Email and Password entered are invalid",
      });

      errorToast(message);
    }
    this.updateState({ ui: { ...ui, isSignInLoading: false } });
  };
  // google sign in call
  onGoogleSignIn = async () => {
    localStorage.clear();
    const { ui } = this.state;
    this.updateState({ ui: { ...ui, isSignInLoading: true } });
    try {
      const response = await userGoogleSignIn();
      console.log(response);
      const { message, status, data } = response;
      console.log(response);

      if (status) {
        const { token, id, user_code } = data.user;
        this.updateState({
          userData: data,
          status,
          message,
          token,
          id,
          user_code,
          ui: { ...ui, isSignInLoading: false },
        });
        this.updateLocalStorage();
        successToast("Login Successful");
      } else {
        errorToast(message);
      }
    } catch (error) {
      const { message } = error;
      this.updateState({
        message: "Google Sign in failed",
      });

      errorToast(message);
    }
    this.updateState({ ui: { ...ui, isSignInLoading: false } });
  };

  onGetUser = async () => {
    const { ui } = this.state;

    this.updateState({ ui: { ...ui, isGetUserLoading: true } });
    try {
      const response = await getUser(this.state.token);
      const { message, data, status } = response;

      if (status) {
        this.updateState({
          userData: data,
          status,
          message,
          ui: { ...ui, isGetUserLoading: false },
        });
        this.updateLocalStorage();
      } else {
        errorToast(message);
      }
    } catch (error) {
      const { message } = error;
      errorToast(message);
    }
    this.updateState({ ui: { ...ui, isGetUserLoading: false } });
  };
  onVisitngUserSave = async (dataToBeSent) => {
    const { ui } = this.state;

    this.updateState({ ui: { ...ui, isUserVisitingLoading: true } });
    try {
      const response = await postVisitingUserData(dataToBeSent);
      const { message, data, status } = response;

      if (status) {
        console.log("user visiting saved");
      } else {
        errorToast(message);
      }
    } catch (error) {
      const { message } = error;
      errorToast(message);
    }
    this.updateState({ ui: { ...ui, isUserVisitingLoading: false } });
  };

  onGetAllUsers = async () => {
    const { ui } = this.state;

    this.updateState({ ui: { ...ui, isGetUserLoading: true } });
    try {
      const response = await getAllUsers(this.state.token);
      const { message, data, status } = response;

      if (status) {
        this.updateState({
          users: data,
          status,
          message,
          ui: { ...ui, isGetUserLoading: false },
        });
        this.updateLocalStorage();
      } else {
        errorToast(message);
      }
    } catch (error) {
      const { message } = error;
      errorToast(message);
    }
    this.updateState({ ui: { ...ui, isGetUserLoading: false } });
  };

  onGetUserByCode = async (code) => {
    const { ui } = this.state;

    this.updateState({ ui: { ...ui, isGetUserLoading: true } });
    try {
      const response = await getUserByCode(code);
      const { message, data, status } = response;

      if (status) {
        this.updateState({
          userData: data,
          status,
          message,
          ui: { ...ui, isGetUserLoading: false },
        });
        this.updateLocalStorageForProfile();
      } else {
        errorToast(message);
      }
    } catch (error) {
      const { message } = error;
      errorToast(message);
    }
    this.updateState({ ui: { ...ui, isGetUserLoading: false } });
  };

  onGetUserByName = async (code) => {
    const { ui } = this.state;

    this.updateState({ ui: { ...ui, isGetUserLoading: true } });
    try {
      const response = await getUserByName(code);
      const { message, data, status } = response;

      if (status) {
        console.log("DATA", data);
        this.updateState({
          userData: data,
          status,
          message,
          ui: { ...ui, isGetUserLoading: false },
        });
        this.updateLocalStorageForProfile();
      } else {
        errorToast(message);
      }
    } catch (error) {
      const { message } = error;
      errorToast(message);
    }
    this.updateState({ ui: { ...ui, isGetUserLoading: false } });
  };

  isAdmin = () => {
    const { userData } = this.state;
    let isAdmin = false;
    if (userData && userData.isAdmin) {
      isAdmin = true;
    }
    return isAdmin;
  };

  onPutUser = async (params, id) => {
    const { ui } = this.state;

    this.updateState({ ui: { ...ui, isPutUserLoading: true } });
    try {
      const response = await putUser(params, id);
      const { message, data, status } = response;

      if (status) {
        this.updateState({
          userData: data,
          status,
          message,
          ui: { ...ui, isPutUserLoading: false },
        });
        this.updateLocalStorage();
        successToast("Profile details updated");
      } else {
        errorToast(message);
      }
    } catch (error) {
      const { message } = error;
      errorToast(message);
    }
    this.updateState({ ui: { ...ui, isPutUserLoading: false } });
  };

  onUploadAvatar = async (params, id) => {
    const { ui } = this.state;

    this.updateState({ ui: { ...ui, isPutUserLoading: true } });
    try {
      const response = await uploadAvatar(params, id);
      const { message, data, status } = response;

      if (status) {
        this.updateState({
          userData: data,
          status,
          message,
          ui: { ...ui, isPutUserLoading: false },
        });
        this.updateLocalStorage();
        successToast("New Avatar Uploaded");
      } else {
        errorToast(message);
      }
    } catch (error) {
      const { message } = error;
      errorToast(message);
    }
    this.updateState({ ui: { ...ui, isPutUserLoading: false } });
  };

  onDeleteUser = async (id) => {
    const { ui } = this.state;

    this.updateState({ ui: { ...ui, isPutUserLoading: true } });
    try {
      const response = await deleteUser(id, this.state.token);
      const { message, data, status } = response;

      if (status) {
        this.updateState({
          userData: data,
          status,
          message,
          ui: { ...ui, isPutUserLoading: false },
        });
        this.updateLocalStorage();
        successToast("Profile details updated");
      } else {
        errorToast(message);
      }
    } catch (error) {
      const { message } = error;
      errorToast(message);
    }
    this.updateState({ ui: { ...ui, isPutUserLoading: false } });
  };

  onCreateMailingList = async (username, body) => {
    const { ui } = this.state;

    this.updateState({ ui: { ...ui, isGetUserLoading: true } });
    try {
      const response = await mailingList(username, body);
      const { message, data, status } = response;

      if (status) {
        this.updateState({
          status,
          message,
          ui: { ...ui, isGetUserLoading: false },
        });
        this.updateLocalStorage();
        successToast("Email added to the mailing list");
      } else {
        errorToast(message);
      }
    } catch (error) {
      const { message } = error;
      errorToast(message);
    }
    this.updateState({ ui: { ...ui, isGetUserLoading: false } });
  };

  onCreateMeetingTimings = async (username, body) => {
    const { ui } = this.state;

    this.updateState({ ui: { ...ui, isGetUserLoading: true } });
    try {
      const response = await meetingListApi(username, body);
      const { message, data, status } = response;

      if (status) {
        this.updateState({
          status,
          message,
          ui: { ...ui, isGetUserLoading: false },
        });
        this.updateLocalStorage();
        successToast("Meet timings added!");
      } else {
        errorToast(message);
      }
    } catch (error) {
      const { message } = error;
      errorToast(message);
    }
    this.updateState({ ui: { ...ui, isGetUserLoading: false } });
  };

  onGenerateCode = async () => {
    const { ui } = this.state;

    this.updateState({ ui: { ...ui, isGetUserLoading: true } });
    try {
      const response = await generateCode();
      const { message, data, status } = response;

      if (status) {
        this.updateState({
          user_code: data,
          status,
          message,
          ui: { ...ui, isGetUserLoading: false },
        });
        this.updateLocalStorage();
      } else {
        errorToast(message);
      }
    } catch (error) {
      const { message } = error;
      errorToast(message);
    }
    this.updateState({ ui: { ...ui, isGetUserLoading: false } });
  };

  // @action
  updateState = (params) => {
    const { state } = this;
    this.state = {
      ...state,
      ...params,
    };
  };

  updateLocalStorage = () => {
    setState("userData", this.state?.userData);
    setState("token", this.state?.token);
    setState("id", this.state?.id);
    setState("user_code", this.state?.user_code);
    setState("status", this.state?.status);
    setState("message", this.state?.message);
    setState("users", this.state?.users);
  };
  updateLocalStorageForProfile = () => {
    setState("userDataProfile", this.state?.userData);
  };

  // @action
  reset = () => {
    this.state = initialState;
    clearState();
  };
  resetProfile = () => {
    localStorage.removeItem("userDataProfile");
  };
}

const AuthStoreContext = React.createContext(null);

export const useAuthStore = () => React.useContext(AuthStoreContext);

export default AuthStore;
